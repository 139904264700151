import {React, useState} from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ContactModal from './components/Modals/ContactModal'
import ssLogo from './img/softdof_logo.svg';
import sspLogo from './img/softwarespareparts_logo.svg';
import AdHostHorizontal from './components/AdHosts/AdHostHorizontal';

const Footer = () =>{
  const [isContactModalVisible, setContactModalState] = useState(false);

  const hideContactModal = () => {
    setContactModalState(false);
  };

  const showContactModal = (event) => {
    event?.preventDefault();
    setContactModalState(true);
  };

  const t = 
  <>
  <div class="blog footer" style={{margin:'0em'}}>
    <div class="container-fluid p-4 text-center" style={{margin:'0em'}}>
      <div class="row">
        <div class="col-sm-1" style={{textAlign:'left'}}>
          <img src={sspLogo} alt="ConceptsOnFire" class="img-fluid" width={'48px'} />
        </div>
        <div class="col-sm-3" style={{textAlign:'left', margin:'0px'}}>
          <p style={{margin:'0em'}}>An innovative project by SoftDOF Supercomputing: <a href='https://www.softdof.com/' target="_blank" rel="noreferrer" style={{margin:'0em', color:'white'}}>www.softdof.com</a>.</p>
          <p style={{margin:'0em'}}>We are always eager to hear from you. Please feel free to <button type="button" class="btn btn-link" onClick={showContactModal} >Contact us</button>.</p>
        </div>
        <div class="col-sm-8" style={{textAlign:'right'}}>
          <br />
          <p style={{margin:'0em'}}><a href="https://www.softdof.com" target="_blank" rel="noreferrer"><img src={ssLogo} alt="SoftDOF Supercomputing" class="img-fluid filter-white" width="48px" /></a>
            &nbsp;&nbsp; &copy; Copyright SoftDOF Supercomputing Pvt. Ltd. 2024.
          </p> 
        </div>
      </div>
    </div>
  </div>
  <Modal open={isContactModalVisible} onClose={hideContactModal} center>
  <ContactModal isOpen={isContactModalVisible} onClose={hideContactModal} />
</Modal>
</>
  return t;
}

export default Footer;

