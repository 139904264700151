import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

function QuillEditor(props) {
  const [editorValue, setEditorValue] = useState( props.Html);
  const quillRef = useRef();

  useEffect(() => {
    setEditorValue(props.Html);
  }, [props.Html]);

  const handleOnChange = (value) =>{
    setEditorValue(value);
    props.HandleChange(value);
  };
 
  var quill = <ReactQuill
    placeholder='Write comment...'
    value={editorValue}
    onChange= {(value) => handleOnChange(value)}
    theme="snow"
    ref={quillRef}
  />;

  return ( quill );
}

export default QuillEditor;
