import React, { useState, useEffect } from 'react';
import CommentEditor from './Quill/CommentEditor';
import parse from 'html-react-parser';
import Voter  from './Voter';
import { useAlert } from 'react-alert'

function CommentsViewer(props) {
  const [commentsJson, setCommentsJson] = useState("");
  const [showReplyEditorForCommentId, setShowReplyEditorForCommentId] = useState("");
  const [showReplyEditorForReplyId, setShowReplyEditorForReplyId] = useState("");
  const [replyHtml, setReplyHtml] = useState("");
  const [replyTitle, setReplyTitle] = useState("");
  const [articleKey, setArticleKey] = useState(props.articleKey);
  const alert = useAlert();

  useEffect(() => {
    setCommentsJson(props.comments);
  }, [props.comments]);

  useEffect(() => {
    setArticleKey(props.articleKey);
  }, [props.articleKey]);

  const onDiscardButtonClick = () => {
    setShowReplyEditorForCommentId("");
    setShowReplyEditorForReplyId("");
  }

  const getReplyHtml = (reply) => {
    if(showReplyEditorForReplyId === reply.Id) {
      var ce = <CommentEditor 
      hasTitle={false} 
      commentHtmlChanged={replyHtmlChanged} 
      titleChanged={replyTitleChanged} 
      handleSubmitButtonClick={()=>{handleSubmitReplyButtonClick(1, reply.CommentId, reply.Id)}}
      showDiscardButton={true}
      onDiscardButtonClick={onDiscardButtonClick}
       />;
    }

    return (
        <div style={{marginLeft:'1em', marginTop:'0em', marginBottom:'0em'}}>
          {/* <QuillCommentViewer Html={reply.Text}/> */}
          <p>{parse(reply.Text)}</p>
          <p>-{reply.UserFullName}, {reply.Date}</p>
          <span style={{cursor:"pointer"}} onClick={()=>{addReplyToReply(reply.Id)}} >Reply to reply.</span>
          {ce};
        </div>
      )
  }

  const getReplyHtmlRecursive = (replies) => {
    var reps = replies;
    var nReps = reps.length;
    var html = [];
    for(var i = 0; i < nReps; i++) {
      html.push(getReplyHtml(reps[i]));
      var subReplies = reps[i].Replies;
      var nSubReplies = subReplies.length;
      if(nSubReplies > 0) {
        html.push(getReplyHtmlRecursive(subReplies));
      }
    }

    return html;
  }

  function getReplies(replies, commentId) {
    var ret;
    if(replies.length === 0) {
      ret = <p>No replies yet.</p>
    } else {
      ret = getReplyHtmlRecursive(replies);
    }

    var rep;
    var ce = <></>;
    if(showReplyEditorForCommentId === commentId) {
      ce = <CommentEditor 
      hasTitle={false} 
      commentHtmlChanged={replyHtmlChanged} 
      titleChanged={replyTitleChanged} 
      handleSubmitButtonClick={()=>{handleSubmitReplyButtonClick(0, commentId, "")}}
      showDiscardButton={true}
      onDiscardButtonClick={onDiscardButtonClick}
       />
    }

    rep = <>{ret}<span style={{cursor:"pointer"}} onClick={()=>{addReplyToComment(commentId)}} >Reply to comment.</span>{ce}</>;

    return rep;
  }

  const replyHtmlChanged = (value) => {
    setReplyHtml(value);
  }
  const replyTitleChanged = (value) => {
    setReplyTitle(value);
  }

  const handleSubmitReplyButtonClick = (inputType, commentId, replyId) => {
    if(window.confirm("Are you sure you want to submit the reply?")) {
      submitReply(inputType, commentId, replyId);
    }
  }

  const submitReply = (inputType, _commentId, _replyId) => {

    if(replyHtml === "<p></p>")
    {
      alert.show("Please enter a reply and hit 'Submit'", {type: 'error'});
      return;
    }

    var userData = JSON.parse(sessionStorage.getItem("userData"));
    var userId = "0"; // not logged in
    if(userData!== undefined || userData !== null) {
      userId = userData.userId;
    }

    var email = userData.email;
    var artKey = articleKey;
    var post_type = props.post_type;
    var operation = 0; // because we want to add a comment (or reply). 1 if it is to be deleted -> to be done only by moderator
    var itemType = inputType;
    var userFullname = userData.userFullname;
    var text = replyHtml;
    var title = replyTitle;
    var articleId = props.articleKey;
    var commentId = _commentId;
    var replyId = _replyId;

    var formData = new FormData();
      formData.append('userId', userId); 
      formData.append('email', email);
      formData.append('articleKey', artKey);
      formData.append('post_type', post_type);
      formData.append('operation', operation);
      formData.append('itemType', itemType);
      formData.append('userFullname', userFullname);
      formData.append('text', text);
      formData.append('title', title);
      formData.append('articleId', articleId);
      formData.append('commentId', commentId);
      formData.append('replyId', replyId);

      fetch('/api/updateCommentsAndReplies.php', {
        method: 'POST',
        body: formData,
      })
        //  .then(response => response.text())
         .then(response => response.json())
         .then(data => {
            alert.show(data.message, {type: 'info'});
            setShowReplyEditorForCommentId("");
            setShowReplyEditorForReplyId("");
            props.updateComments(data.data.comments);

            console.log(data.message);
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          alert.show(error, {type: 'error'});
        });




  }

  const addReplyToReply = (replyId) => {
    setShowReplyEditorForCommentId("");
    if(showReplyEditorForReplyId === replyId) {
      setShowReplyEditorForReplyId("");
    } else {
      setShowReplyEditorForReplyId(replyId);
    }    
  }


  const addReplyToComment = (commentId) => {
    setShowReplyEditorForReplyId("");
    if(showReplyEditorForCommentId === commentId) {
      setShowReplyEditorForCommentId("");
    } else {
      setShowReplyEditorForCommentId(commentId);
    }    
  }

  const onSubmitVote = (commentId, vote) => {
    var userData = JSON.parse(sessionStorage.getItem("userData"));
    var userId = "0"; // not logged in
    if(userData!== undefined || userData !== null) {
      userId = userData.userId;
    }
    if(userId === 0) {
      alert.show("You must be logged in to vote.", {type: 'error'});
      return;
    }
    var userEmail = userData.email;
    var artKey = articleKey;

    var formData = new FormData();
      formData.append('userEmail', userEmail);
      formData.append('articleKey', artKey);
      formData.append('commentId', commentId);
      formData.append('rating', vote);

      fetch('/api/submitCommentRating.php', {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        body: formData,
      })
        //  .then(response => response.text())
          .then(response => response.json())
          .then(data => {
          //  alert(data);
          alert.show(data.message, {type: 'info'});

           props.updateComments(data.data.comments);

          console.log(data.message);
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          alert.show(error, {type: 'error'});
        });

  }

  const getComments = () => {
    if(commentsJson === undefined || commentsJson === ""){
      return <p style={{marginLeft:'1em', marginTop:'0em', marginBottom:'0em'}}>No comments made yet.</p>  
    }

    var c = JSON.parse(commentsJson);
    var comments = c.data.comments;
    
    var nComments = comments.length;

    if(nComments === 0) {
      return <p style={{marginLeft:'1em', marginTop:'0em', marginBottom:'0em'}}>No comments made yet.</p>  
    }

    var userDataJson = sessionStorage.getItem("userData");
    var userEmail = "";
    if(userDataJson !== "") {
      var userData = JSON.parse(userDataJson);
      if(userData !== null) {
        userEmail = userData.email;
      }
    }


    // iterate over all the comments, their replies and their replies in recursion
    var html =[];
    for(var i = 0; i < nComments; i++) {
      var name = comments[i].UserFullName;
      var dt = comments[i].Date;
      var title = comments[i].Title;
      var text = comments[i].Text;
      var replies = comments[i].Replies;
      var id = comments[i].Id;
      var rep = getReplies(replies, id);

      var votesJson = comments[i].UpDowns;

      var votes = getCommentVotes(votesJson, userEmail);
      var pos = votes[0];
      var neg = votes[1];
      var userVote = votes[2];

      html.push(
        <div style={{marginLeft:'1em', marginTop:'0em', marginBottom:'0em'}}>
          <span style={{fontWeight:"bold", margin:'0em'}}>{title} &nbsp; <Voter commentId={id} vote={userVote} positiveCount={pos} negativeCount={neg} onSubmitVote={onSubmitVote} /></span>
          <p style={{margin:'0em'}}>{parse(text)}</p>
          <p style={{margin:'0em'}}>-{name}, {dt}</p>
          <div style={{marginLeft:'1em', marginTop:'0em', marginBottom:'0em'}}>
            {rep}
          </div>
        </div>
      );
    }
    html.push(<br></br>);
    return html;
  }


  const getCommentVotes = (udJson, userEmail) => {
    console.log(udJson);
    
    var p = 0;
    var n = 0;
    var userVote = 0;
    if(udJson !== "" && typeof(udJson) === "string") {
      var upDowns = JSON.parse(udJson);
      var nud = upDowns.length;
      for(var j = 0; j < nud; j++) {
        let v = Number(upDowns[j].Value);
        if(v > 0) p += v;
        if(v < 0) n += v;
        if(upDowns[j].UserEmail === userEmail) userVote = v;
      }
    }

    return [p, n, userVote];
  }

  return <>{ getComments(commentsJson) }</>
}

export default CommentsViewer;

