import React from 'react';
import {useState, useEffect } from "react";
import useLogin from '../../../components/Hooks/useLogin';
import Avatar from 'react-avatar';
import { click } from '@testing-library/user-event/dist/click';
import { useAlert } from 'react-alert'
import { positions, transitions, types } from 'react-alert'
import logo from '../../../specific/webicon.svg';

// import { useNavigate } from "react-router-dom";
function Navbar(props) {
  const alert = useAlert();

  const[email, setEmail] = useState("");
  const[username, setUsername] = useState("");
  const[loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setUsername(props.username);
  }, [props.username]);
  
  useEffect(() => {
    setLoggedIn(props.loggedIn);
  }, [props.loggedIn]);




  var userDataJson = sessionStorage.getItem("userData");
  var ud = JSON.parse(userDataJson);

  var isAdmin = false;
  var isReviewer = false;
  var isModerator = false;
  
  if(ud !== null) {
    isAdmin = false;
    if(Number(ud.isAdmin) === 1 || ud.isAdmin === true) isAdmin = true;
    isReviewer = false;
    if(Number(ud.isReviewer) === 1 || ud.isReviewer === true) isReviewer = true;
    isModerator = false;
    if(Number(ud.isModerator) === 1 || ud.isModerator === true) isModerator = true;
  }

 
  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  useEffect(() => {
    setUsername(props.username);
  }, [props.username]);

  useEffect(() => {
    setLoggedIn(props.loggedIn);
  }, [props.loggedIn]);

  const handleLoginButtonClick = () => {
    props.onHandleLoginClick();
  }

  const handleRegisterButtonClick = () => {
    props.onHandleRegisterClick();
  }

  const handleProfileButtonClick = (event) => {
    props.onProfileButtonClick();
  }

  const handleLogoutButtonClick = () => {
    fetch('/api/logout.php', {
      method: 'POST'
    })
    .then()
      .then(data => {
        props.onHandleLogoutClick();
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show(error, {type: 'error'});
      });
  }

  const getUserPosition = () => {

    if(isAdmin === true) return <b>Administrator</b>;
    if(isReviewer === true) return <b>Reviewer</b>;
    if(isModerator === true) return <b>Moderator</b>;
    return <></>
  }

  const handleManageUsersClick = (event) => {
    event?.preventDefault();
    props.onManageUsers();
  }

  const handleReviewerRequestsClick = (event) => {
    event?.preventDefault();
    props.onViewReviewerRequests();
  }

  const getRoleBasedLinks = () => {
    if(isAdmin) {
      return <>&nbsp;
      <a href='' onClick={handleManageUsersClick}>Manage Users</a>
      &nbsp;
      <a href='' onClick={handleReviewerRequestsClick}>Reviewer Requests</a>
      </>
    }
    return <></>
  }

  const onBrandClick = (event) => {
    event.preventDefault();

    props.onBrandClick();
  }

  return (
  <>
    <nav class="navbar navbar-expand-sm bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="" onClick={onBrandClick} ><img src={logo} alt='SS' width={'48px'} /></a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mynavbar">
          <ul class="navbar-nav me-auto">
            {/* <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">Link</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">Link</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">Link</a>
            </li> */}
          </ul>
          
          <a style={{margin:'0rem'}}> {getUserPosition()}</a>
          {getRoleBasedLinks()}
          {(loggedIn === "true" || loggedIn === true) ? <>
              <nav class="main-nav float-right d-none d-lg-block">
                <ul>
                  <li class="drop-down"><a><Avatar name={username} size="32" round={true}  /></a>
                    <ul>
                      <li><a class="nav-link" style={{cursor:'pointer'}} onClick={handleProfileButtonClick}><i>Profile</i></a></li>
                      <li><a class="nav-link" style={{cursor:'pointer'}} onClick={handleLogoutButtonClick}>Log Out <i class="bi bi-box-arrow-right"></i></a></li>
                    </ul>
                  </li>
              </ul>
              </nav>

              </>
            :
              <>
                <button class="btn btn-primary" type="button" onClick={handleRegisterButtonClick}>Sign Up <i class="bi bi-pencil-square"></i></button>
                &nbsp;&nbsp;
                <button class="btn btn-primary" type="button" onClick={handleLoginButtonClick}>Log In <i class="bi bi-box-arrow-in-right"></i></button>

                

              </>
          }
        </div>
      </div>
    </nav>
    </>);
}

export default Navbar;
