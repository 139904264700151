import React from 'react';
import JsonData from '../../../specific/webinfo.json';
import logo from '../../../specific/webicon.svg';

const Header = () =>{
  const t = 
  <div class="blog header">
    <div class="container-fluid mt-3" style={{margin:'0px', padding:'0px'}}>
      <div class="row">
        <div class="col-sm-1 p-3 text-white" style={{textAlign:'left', margin:'0px', padding:'0px'}}>
          <img src={logo} width={'200px'} alt={JsonData.Title} style={{margin:'10px', paddingLeft:'0px', border:'1px solid white' }}/>
        </div>
        <div class="col-sm-11 p-3 text-white" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div class='center' style={{marginLeft:'-8.32%'}}>
            <h1>{JsonData.Title}</h1>
            <h3>{JsonData.SubTitle}</h3>
          </div>
        </div>
      </div>
    </div>


  </div>
  return t;
}

export default Header;

