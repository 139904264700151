import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Footer from './Footer';

import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import HitCounter from './components/HitCounter';

// optional configuration
const options = {
  // you can also just use 'bottom center'
  // position: positions.BOTTOM_CENTER,
  position: positions.MIDDLE,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
      <HitCounter />
    </AlertProvider>
  </React.StrictMode>
);

const bt_footer = ReactDOM.createRoot(document.getElementById('bt_footer'));
bt_footer.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <Footer />
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
