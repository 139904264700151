import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import ArticlePreview from "./Preview/ArticlePreview";

// Example items, to simulate fetching from another resources.
var items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items(props) {

  const getArticlePreview = (art) =>  {
    
    return (
      <>
      <div>
        <ArticlePreview 
          title={art.Title} 
          articleKey={art.Key}
          author={art.AuthorName} 
          rating={art.Rating} 
          dateSubmitted={art.DatePosted} 
          nVotes={art.NumberOfVotes}
          articleId={art.ArticleId}
          authorId={art.AuthorId}
          views={art.Views}
          tags={art.Tags}
          path={art.Path}
          post_type={art.Post_type}
          handleArticlePreviewClicked={props.handleArticlePreviewClicked}
          wholeAreaClickable={props.wholeAreaClickable}
        />
        </div>
        <br/>
      </>
    )
  }

  return (
    <>
      {props.currentItems &&
        props.currentItems.map((art) => (
          <div>
            {
              getArticlePreview(art)
            }
          </div>
        ))}
    </>
  )
}


function PaginatedItems(props) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [allArticles, setAllArticles] = useState([]);
  useEffect(() => {
    items = props.allArticles;
    setAllArticles(props.allArticles);
  }, [props.allArticles]);


  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + props.itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / props.itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
 
 

  return (
    <>
      <Items currentItems={currentItems} handleArticlePreviewClicked={props.handleArticlePreviewClicked}
          wholeAreaClickable={props.wholeAreaClickable}/>
          <div class="center">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
              renderOnZeroPageCount={null}
            />
          </div>
    </>
  );
}

export default PaginatedItems;