import * as React from "react";
import { useState, useEffect } from "react";

function Tags(props) {
    const[values, setValues] = useState([]);

    useEffect(() => {
        setValues(props.values);
      }, [props.values]);
      
  const getLinkTextSpacer = (index) => {
    var n = values.length;
    if(index < n-1) {
        return <>&nbsp;&nbsp;</>
    } else {
        return <></>
    }
  }

 
  const getTags = (i) => {
    return <div class="btn btn-static">{values[i]}</div>
  }

  var indices = Array.from(values.keys());

  return (
    <div class="row">
            <div class="col-sm-9">
                <div class="input-group center">
                    {
                        indices.map((i) => <>{getTags(i)}{getLinkTextSpacer(i)}</>)
                    } 
                </div>
            </div>           
    </div>
  )
}

export default Tags;
