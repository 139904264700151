import React from "react"

// props MUST have ss which should contain an array of objects for each desired column
// each array element must have a Columns (number of bootstrap columns) and Element (Component) as their children.
// props.ss --> array of objects
// props.ss[i].Columns = number of bootstrap columns in this column
// props.ss[i].Element = the component to be rendered in this column

function LayoutElement(props) {
    return (
        <div className="container-fluid mt-3" >
          <div className="row" >
            {props.ss.map((c, index) => (
                <div key={index} className={"col-sm-" + c.Columns}>{ c.Element }</div>
            ))}
          </div>
        </div>
    );
}

export default LayoutElement;