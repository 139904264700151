import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PaginatedItems from "./PaginatedItems";

function ArticleList(props) {
  const [allArticleList, setAllArticleList] = useState([]);

  useEffect(() => {
    setAllArticleList(props.allArticleList);
  }, [props.allArticleList]);

  // useEffect(()=>{
  //   handleSubmit();
  // }, []); // <-- empty dependency array. This forces run only once such as on a constructor. 
  // // See: https://stackoverflow.com/questions/58101018/react-calling-a-method-on-load-only-once

var nArticles = allArticleList.length;

  return (
    <> 
  <div>
    {
      nArticles === 0 ? <h2>No data found.</h2>
      :
      <div id='paginator'>
        <PaginatedItems allArticles={allArticleList} itemsPerPage={10} handleArticlePreviewClicked={props.handleArticlePreviewClicked}
          wholeAreaClickable={props.wholeAreaClickable}/>
      </div>
    }
    </div>
    </>    
  );
  
}

export default ArticleList;

 