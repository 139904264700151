import React, { useState } from "react";

function RegisterModal ({ onClose, onRegister, onLogin, children }) {
    const [email, setEmail] = useState("");
    const [fullname, setFullname] = useState("");
    const [password, setPassword] = useState("");
    const [apiResponse, setApiResponse] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [codeSentMessage, setCodeSentMessage] = useState("");

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  const handleGeneratePassword = () => {
    var pwd = generatePassword();
    setPassword(pwd);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFullNameChange = (event) => {
    setFullname(event.target.value);
  };

  const handleOnLoginClicked = (event) => {
    event?.preventDefault();
    onLogin();
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    onClose();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowVerification(true);
  }

    const getRegistrationControls = () => {
    return (
          <div id="registerModal">
    <div class="container">
      <h2>Sign Up</h2>
      <form class="form-horizontal" >

        {/* Ask for the full name */}
        <div class="input-group">
          <span class="input-group-text bg-transparent">Full Name: </span>
          <input class="form-control border-end-0 border rounded-pill" onChange={handleFullNameChange} name="fullname" type="text" id="fullname" value={fullname} placeholder="Enter full name"/>
        </div>
        <br></br>

        {/* Ask for the email id */}
        <div class="input-group">
          <span class="input-group-text bg-transparent">Email: </span>
          <input class="form-control border-end-0 border rounded-pill" onChange={handleEmailChange} name="email" type="text" id="email" value={email} placeholder="Enter email"/>
        </div>
        <br></br>

        {/* Ask for the password */}
        <div class="input-group">
          <span class="input-group-text bg-transparent">Password: </span>
          <input class="form-control border-end-0 border rounded-pill" value={password} type={showPassword?'text':'password'} onChange={handlePasswordChange} placeholder="Password"/>
          <span class="input-group-append">
            <button class="btn btn-outline-secondary border-bottom-0 border rounded-pill ms-n5" type="button" onClick={handleShowPassword}>
              {
                showPassword ?
                <><i class="bi bi-eye-slash"></i></>
                :
                <><i class="bi bi-eye"></i></>
              }
            </button>
            <button 
              class="btn btn-outline-secondary border-bottom-0 border rounded-pill ms-n5" 
              type="button"
              onClick={handleGeneratePassword}
              >
              <i class="bi bi-magic"></i>
            </button>
          </span>
        </div>

        <br></br>

        <div class="form-group">
          {/* <div class="col-sm-offset-2 col-sm-10"> */}
            <button class="btn btn-warning" onClick={onCancelClick}>Cancel</button>
            &nbsp;
            <button onClick={handleSubmit} class="btn btn-primary">Submit</button>
          {/* </div> */}
        </div>
        <br />
        <div>
          <p>
            Already have an account? <button type="button" class="btn btn-link" onClick={handleOnLoginClicked}>Sign In</button>.
          </p>
        </div>
      </form>
    </div>
    </div>
    );
  }

  const onBackToRegister = (event) => {
    event.preventDefault();
    setShowVerification(false);
  }

  const onSendCodeClick = (event) => {
    event?.preventDefault();
     
    var formData = new FormData();
    formData.append('email', email);
    formData.append('fullname', fullname);

    fetch('/api/sendEmailVerificationCode.php', {
      method: 'POST',
      body: formData,
    })
    // .then(response => response.text())
    .then(response => response.json())
      .then(data => {
        if(data.status === "success") {
          setCodeSentMessage("A code has been sent to the email id you registered with.");
          window.alert("(delete this link later) link = " + data.data.link)
          console.log(data.message);
        } else {
          window.alert(data.message);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        window.alert("There was an error in processing register request.\n\n" + error);
        setApiResponse(error);
      });

      setCodeSentMessage("Preparing to send code. Please wait.");
  }

  const onVerifyCodeClick = (event) => {
    event?.preventDefault();

    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('fullname', fullname);
    formData.append('verificationCode', verificationCode);

    fetch('/api/register.php', {
      method: 'POST',
      body: formData,
    })
     .then(response => response.json())
    //  .then(response => response.text())
      .then(data => {
        if(data.status === "success") {
          window.alert(data.message);
          console.log(data.message);
          setApiResponse(data.message);
          onRegister();
          onClose();
        } else {
          window.alert(data.message);
          onClose();
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        window.alert("There was an error in processing register request.\n\n" + error);
        setApiResponse(error);
      });
  }

  const getVerificationControls = () => {
    return (
      <div id="verificationModal">
        <div class="container">
          <h2>Verify Registration</h2>
          <form class="form-horizontal" >
          <p style={{marginBottom:"1em"}}>To verify your registration, we will send you a One Time Code to your email. Please enter the code and hit 'Verify'.</p>
            <div class="form-group">
              <span><button class="btn btn-primary" onClick={onSendCodeClick}>Send Code</button> <p><b>{codeSentMessage}</b></p></span>
            </div>
            <br></br>

            {/* Ask for the email id */}
            <div class="input-group">
              <span class="input-group-text bg-transparent">Code: </span>
              <input class="form-control border-end-0 border rounded-pill" onChange={handleVerificationCodeChange} name="code" type="text" id="code" value={verificationCode} placeholder="Enter code"/>
            </div>

            <br></br>

            <div class="form-group">
                <button onClick={onBackToRegister} class="btn btn-info">Back</button>
                &nbsp;
                <button onClick={onVerifyCodeClick} class="btn btn-primary">Verify</button>
            </div>
            <br />
            <div>
              <p>
                Already have an account? <button type="button" class="btn btn-link" onClick={handleOnLoginClicked}>Sign In</button>.
              </p>
            </div>
          </form>
        </div>
        </div>
        );    
    
  }

  return (   
    <>
      {showVerification ? getVerificationControls() : getRegistrationControls()}
    </>
  );
}

export default RegisterModal;

