import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useAlert } from 'react-alert'

function AttachedFiles(props) {
  const [selectedFile, setSelectedFile] = useState(props.draftPath);
  const [files, setFiles] = useState(props.files);
  const draftPath = props.draftPath;
  const inputFile = useRef(null);
  const [showUpload, setShowUpload] = useState(true);
  const alert = useAlert();  

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  useEffect(() => {
    setShowUpload(props.showUpload);
  }, [props.showUpload]);


  // useEffect(() => {
  //   const loggedIn = sessionStorage.getItem("loggedIn");
  //   if (!loggedIn) {
  //     navigate("/");
  //   }
  // }, [navigate]);

    // Function to reset the input element
    const resetFileInput = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };
 
    // On file select (from the pop up)
    const onFileChange = (event) => {
        // Update the state
        setSelectedFile(event.target.files[0]);
    };

    // On file upload (click the upload button)
    const onFileUpload = () => {

		// Details of the uploaded file
		console.log(selectedFile);

      // React component making an API call to a PHP script
      var data = new FormData()
      data.append('fileToUpload', selectedFile)
      data.append('path', draftPath)

      fetch('/api/uploadAttachment.php', {
          mode: 'cors',
          credentials: 'include',
          method: 'POST',
          body: data
      })
      .then(response => response.json())
      // .then(response => response.text())
      .then(data => {
          // Handle the data received from the PHP script
          console.log(data);
         // alert(data);

          if(data.status === "success") {
              sessionStorage.setItem("draftArticlePath", data.data.path);
              setSelectedFile('');
              resetFileInput();
              setFiles(JSON.parse(data.data.files));
          }
          else if(data.status === "error") {
              alert.show(data.message, {type: 'error'});
          }
          else if(data.status === "logout") {
              alert.show(data.message, {type: 'error'});
          }
      })
      .catch(error => {
          // Handle any errors here
          console.error('Error fetching data:', error);
          alert.show(error, {type: 'error'});
      });
	};

  const onClickDownloadFiles = (event) => {
    
    event.preventDefault();
    console.log("------------------------------- BT: DOWNLOADING ATTACHMENTS");
    
    var filename = 'article-files.zip';
    if(props.post_type !== 0) {
      filename = 'question-files.zip';
    }

          var formData = new FormData();
          formData.append('path', draftPath);
          formData.append('articleKey', props.articleKey);
    
          var userData = JSON.parse(sessionStorage.getItem("userData"));
          var userId = "0"; // not logged in
          if(userData!== undefined || userData !== null) {
            userId = userData.userId;
          }

          if(userId === 0) {
            alert.show("Please login to download attached files.", {type: 'info'});
            return;
          }

          formData.append('userId', userId);
    
          fetch('/api/downloadAttachments.php', {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            response: 'blob',
            body: formData,
          }).then(response => response.blob())
            // .then(response => response.json())
            .then(blob => {
              // Creating new object of PDF file
              const fileURL =
              window.URL.createObjectURL(blob);
               
              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = filename;
              alink.click();  
              
              })  
            .catch(error => {
              // Handle any errors here
              console.error(error);
              alert.show(error, {type: 'info'});
            });
      }
  
  return (
    <>
    <div>
      <p style={{fontWeight:"bold"}}>Attachments:</p>
        {
            files.map((file, index) => <option value={file}>{index+1}. {file}</option>)
        }
        {files.length > 0 ? 
            (files.length === 1 ? <p>Download File</p> : <button type="button" class="btn btn-link" onClick={onClickDownloadFiles}>Download Files</button>) : <></> }
        
{showUpload ?      
      <div>
		<input
      id="img"
			type="file" placeholder="Enter your text here.."
			onChange={onFileChange}
      ref={inputFile}
      // style={{display:"none"}}
		/>
    {/* <label for="img">Click me to upload image</label> */}
		<button onClick={onFileUpload}>Upload!</button>
	  </div> : <></>}

    
    </div>
    </>    
  );
}

export default AttachedFiles;
