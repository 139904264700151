import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DraftList(props) {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/");
    }
  }, [navigate]);
  const onDraftArticleClicked = (index, posttype) => {
    // if(posttype === 0)
    props.onDraftClicked(index, posttype);
  }
  
  const onDeleteDraftArticleClicked = (index, post_type) => {
    if(window.confirm("Are you sure you want to delete this draft?")) {
      props.onDeleteDraftArticleClicked(index, post_type);
    }
  }    

  const getDraftArticles = () =>{
    var articles = props.articles;
    if(articles.length === 0) 
    {
        return <><p style={{textAlign:"left", fontStyle:"italic"}}>No articles available</p></>
    }
 
    return <>
        <p style={{textAlign:"left", fontWeight:"bold", margin:"0"}}>Draft Components:</p>
        {
            articles.map((art, index) => 
            <>
              {
                art.Title === null || art.Title === undefined || art.Title === "" ?
                  <p href="" style={{margin:"0", textAlign:"left"}} >
                    <span style={{cursor:"pointer", fontStyle:"italic"}} onClick={(e) => {e.preventDefault(); onDraftArticleClicked(index, 0)}} >{index + 1}. {"unnamed"}</span>
                    <span style={{cursor:"pointer" }} onClick={(e) => {e.preventDefault(); onDeleteDraftArticleClicked(index, 0)}}> [Delete]</span>
                  </p>  
                :
                  <p>
                  <span style={{cursor:"pointer", margin:"0", textAlign:"left"}} onClick={(e) => {e.preventDefault(); onDraftArticleClicked(index, 0)}}>{index + 1}. {art.Title}</span>
                  <span style={{cursor:"pointer"}} onClick={(e) => {e.preventDefault(); onDeleteDraftArticleClicked(index, 0)}}> [Delete]</span>
                  </p>
              }
            </>)
        }
        
    </>
  }

  const getDraftQuestions = () =>{
    var questions = props.questions;
    if(questions.length === 0) 
    {
        return <><p style={{textAlign:"left", fontStyle:"italic"}}>No questions available</p></>
    }

    return <>
        <p style={{textAlign:"left", fontWeight:"bold"}}>Draft Questions:</p>
        {
            questions.map((art, index) => 
            <>
              {
                art.Title === null || art.Title === undefined || art.Title === "" ?
                  <p href="" style={{margin:"0", textAlign:"left"}} >
                    <span style={{cursor:"pointer", fontStyle:"italic"}} onClick={(e) => {e.preventDefault(); onDraftArticleClicked(index, 1)}} >{index + 1}. {"unnamed"}</span>
                    <span style={{cursor:"pointer" }} onClick={(e) => {e.preventDefault(); onDeleteDraftArticleClicked(index, 1)}}> [Delete]</span>
                  </p>  
                :
                  <p>
                  <span style={{cursor:"pointer", margin:"0", textAlign:"left"}} onClick={(e) => {e.preventDefault(); onDraftArticleClicked(index, 1)}}>{index + 1}. {art.Title}</span>
                  <span style={{cursor:"pointer"}} onClick={(e) => {e.preventDefault(); onDeleteDraftArticleClicked(index, 1)}}> [Delete]</span>
                  </p>
              }
            </>)

        }
        
    </>
  }

  return (
    <>
      {getDraftArticles()}
      {getDraftQuestions()}
    </>    
  );
}

export default DraftList;

