import React, { useState, useEffect } from 'react';
import QuillEditor from "./QuillEditor";
import CollapsableEditor from "./CollapsableEditor";
import { useAlert } from 'react-alert'

function ProfileEditor(props) {
  const [profileHtml, setProfileHtml] = useState("");
  const [fullname, setFullname] = useState("");
  const [showPasswordEdit, setShowPasswordEdit] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const alert = useAlert();

  var udJson = sessionStorage.getItem("userData");
  var ud = JSON.parse(udJson);
  var isReviewer_num = Number(ud.isReviewer);
  var isReviewer = isReviewer_num === 1;
  
  useEffect(() => {
    setProfileHtml(props.profileHtml);
  }, [props.profileHtml]);
  useEffect(() => {
    setFullname(props.fullname);
  }, [props.fullname]);

  const onFullnameChange = (event) => {
    setFullname(event.target.value);
  };
  
  const handleCloseButtonClick = () => {
    
  }

  const handleProfileHtmlChange = (event) => {
    setProfileHtml(event);
  }

  const onChangePasswordClick = (event) => {
    event?.preventDefault();
    setShowPasswordEdit(!showPasswordEdit);
  }

  const handleShowCurrentPassword = (event) => {
    setShowCurrentPassword(!showCurrentPassword);
  }
  const handleShowNewPassword = (event) => {
    setShowNewPassword(!showNewPassword);
  }
  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  }
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  }
  const handleNewPassword2Change = (event) => {
    setNewPassword2(event.target.value);
  }

  const handlePasswordUpdateButtonClick = (event) => {
    event?.preventDefault();
    updatePassword();
  }

  const updatePassword = (event) => {

    var ud = sessionStorage.getItem("userData");
    var userData = JSON.parse(ud);
    var email = userData.email;

    event?.preventDefault();

    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', currentPassword);
    formData.append('newpassword', newPassword);

    fetch('/api/updatePassword.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
    // .then(response => response.text())
    .then(response => response.json())
      .then(data => {
        // alert(data);
        // Handle the data received from the PHP script
        console.log(data);
        
        if(data.status === "success") {
          alert.show(data.message, {type: 'success'});
          console.log("Password updated.");
        }
        else if(data.status === "error") {
          alert.show(data.message, {type: 'error'});
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show(error, {type: 'error'});
      });
  }

  const onClickProfileUpdate = (event) => {
    event?.preventDefault();

    var ud = sessionStorage.getItem("userData");
    var userData = JSON.parse(ud);
    var email = userData.email;

    var formData = new FormData();
    formData.append('email', email);
    formData.append('fullname', fullname);
    formData.append('profile', profileHtml);

    fetch('/api/updateProfile.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
    // .then(response => response.text())
    .then(response => response.json())
      .then(data => {
        // alert(data);
        // Handle the data received from the PHP script
        console.log(data);
        
        if(data.status === "success") {
          alert.show(data.message, {type: 'error'});
          console.log("profile updated.");
        }
        else if(data.status === "error") {
          alert.show(data.message, {type: 'error'});
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show(error, {type: 'error'});
      });
  }

  const onReviewerRequestSubmit = (commentHtml) => {
    var ud = sessionStorage.getItem("userData");
    var userData = JSON.parse(ud);
    var email = userData.email;

    var formData = new FormData();
    formData.append('email', email);
    formData.append('fullname', fullname);
    formData.append('details', commentHtml);

    fetch('/api/submitReviewerRequest.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
      // .then(response => response.text())
     .then(response => response.json())
      .then(data => {
          // alert(data);
        // Handle the data received from the PHP script
        console.log(data);
        
        if(data.status === "success") {
          alert.show(data.message, {type: 'success'});
          console.log("reviewer request submitted.");
        }
        else if(data.status === "error") {
          alert.show(data.message, {type: 'error'});
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show(error, {type: 'error'});
      });
  }

  return (
    <>
    <div>
      <p style={{textAlign:'left', fontWeight:'bold', margin:'0em'}}>Profile</p><br></br>

      <label for="quillEditorProfile" style={{margin:'1rem'}} >Information: </label>
      <QuillEditor id='quillEditorProfile' HandleChange={handleProfileHtmlChange} Html={profileHtml} setArticleHtml={setProfileHtml} />

      <label for="fullname" style={{margin:'1rem'}} >Full Name: </label>
      <input id="fullname" type="text" placeholder="Full name" value={fullname} onChange={onFullnameChange} />
    </div>
    <button type="button" class="btn btn-primary" onClick={onClickProfileUpdate} >Update Profile</button>
    <br></br>
    <br></br>

    {
      isReviewer ?
      <>
      <p>Thank you for being a valued <b>Reviewer</b>!</p>
      </>
      :
      <CollapsableEditor
        mainText={"Become a Reviewer"}
        description={"We are always eager to have new reviewers on board. We welcome fresh thought processes and suggestions. Please consider giving a brief details about you and we should be more than happy to review your request."}
        onClickSubmit={onReviewerRequestSubmit}
      />
    }


    <br></br>
    <br></br>

    <div>
      <button type="button" class="btn btn-link" onClick={onChangePasswordClick}>Change Password</button>
      {showPasswordEdit ? <>
      <div>
        <div class="container mt-3">
          <label class="input-group-text bg-transparent" for='currentpwd' >Current Password: </label>
          <div class="input-group">
            <input class="form-control border-end-0 border rounded-pill" id='currentpwd' value={currentPassword} type={showCurrentPassword?'text':'password'} onChange={handleCurrentPasswordChange} placeholder="Current password"/>
            <span class="input-group-append">
              <button class="btn btn-outline-secondary border-bottom-0 border rounded-pill ms-n5" type="button" onClick={handleShowCurrentPassword}>
                {
                  showCurrentPassword ?
                  <><i class="bi bi-eye-slash"></i></>
                  :
                  <><i class="bi bi-eye"></i></>
                }
              </button>
            </span>
          </div>

          <label class="input-group-text bg-transparent" for='newpwd' >New Password: </label>
          <div class="input-group">
            <input class="form-control border-end-0 border rounded-pill" id='newpwd' value={newPassword} type={showNewPassword?'text':'password'} onChange={handleNewPasswordChange} placeholder="New password"/>
            <span class="input-group-append">
              <button class="btn btn-outline-secondary border-bottom-0 border rounded-pill ms-n5" type="button" onClick={handleShowNewPassword}>
                {
                  showCurrentPassword ?
                  <><i class="bi bi-eye-slash"></i></>
                  :
                  <><i class="bi bi-eye"></i></>
                }
              </button>
            </span>
          </div>

          <label class="input-group-text bg-transparent" for='newpwd2' >New Password Again: </label>
          <div class="input-group">
            <input class="form-control border-end-0 border rounded-pill" id='newpwd2' value={newPassword2} type={showNewPassword?'text':'password'} onChange={handleNewPassword2Change} placeholder="New password"/>
            <span class="input-group-append">
              <button class="btn btn-outline-secondary border-bottom-0 border rounded-pill ms-n5" type="button" onClick={handleShowNewPassword}>
                {
                  showCurrentPassword ?
                  <><i class="bi bi-eye-slash"></i></>
                  :
                  <><i class="bi bi-eye"></i></>
                }
              </button>
            </span>
          </div>
          <br/>
          <button type="button" class="btn btn-primary" onClick={handlePasswordUpdateButtonClick} >Update Password</button>
        </div>
      </div>
      </>:<></>}
    </div>

    <div style={{textAlign:'right'}}>
        <button type="button" class="btn btn-success" style={{margin:5}} onClick={handleCloseButtonClick} >Close</button>
    </div>
    </>
  )
}

export default ProfileEditor;

