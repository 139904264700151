import React, { useState, useEffect } from 'react';
import QuillEditor from "./QuillEditor";
import AttachedFiles from "../Preview/AttachedFiles";
import { useAlert } from 'react-alert'
import JsonData from '../../specific/webinfo.json';

function ArticleEditor(props) {
  const [key, setKey] = useState("");
  const [articleHtml, setArticleHtml] = useState("");
  const [files, setFiles] = useState([]);
  const [draftPath, setDraftPath] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleTags, setArticleTags] = useState("");
  const alert = useAlert();

  var username = "";
  var userDataJson = sessionStorage.getItem("userData");
  var userData;
  if(userDataJson != null) {
    userData = JSON.parse(userDataJson);
    username = userData?.username;
  }


  useEffect(() => {
    setArticleHtml(props.articleHtml);
  }, [props.articleHtml]);
  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);
  useEffect(() => {
    setDraftPath(props.draftPath);
  }, [props.draftPath]);
  useEffect(() => {
    setArticleTitle(props.articleTitle);
  }, [props.articleTitle]);
  useEffect(() => {
    setArticleTags(props.articleTags);
  }, [props.articleTags]);

  useEffect(() => {
    setKey(props.articleTags);
  }, [props.articleTags]);

  const getTagCount = () => {
    if(articleTags === null || articleTags === "") {
      return 0;
    }
    var arr = articleTags.split(',');
    var n0 = 0;
    var n = arr.length;

    for (let i = 0; i < n; i++) {
      var s = arr[i].trim();
      if(s === null || s === "") {
        n0++;
      }
    }

    return n - n0;
  }


  const handleSaveButtonClick = (event) => {
    event?.preventDefault();

    if(articleTitle === null || articleTitle === "") {
      alert.show("Please give a title to the article.", {type: 'info'});
      return;
    }

    var tagCount = getTagCount();
    if(tagCount < 3) {
      alert.show("Please give at least 3 tags.", {type: 'info'});
      return;
    }

    const email = userData?.email;

    var formData = new FormData();
    formData.append('draftPath', draftPath);
    formData.append('post_type', props.post_type); // post_type = 0 => article
    formData.append('email', email);
    formData.append('author', username);

    formData.append('articleTitle', articleTitle);
    formData.append('articleTags', articleTags);
    formData.append('articleMatter', articleHtml);

    fetch('/api/saveDraft.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
      // .then(response => response.text())
     .then(response => response.json())
      .then(data => {
          // window.alert(data);
        // Handle the data received from the PHP script
        console.log(data);

        if(data.status === "success") {
          alert.show(data.message, {type: 'success'});

          var k = data.data['key'];
          setKey(k);
          props.handleSaveButtonClick(k, draftPath);
        }
        else if(data.status === "error") {
          alert.show(data.message, {type: 'error'});
        }
        else if(data.status === "logout") {
          alert.show(data.message, {type: 'error'});
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show("Error fetching data: " + error, {type: 'error'});
      });
  }

  const handleSubmitButtonClick = (event) => {
    event?.preventDefault();

    if(articleTitle === null || articleTitle === "") {
      alert.show("Please give a title to the article.", {type: 'info'});
      return;
    }

    var tagCount = getTagCount();
    if(tagCount < 3) {
      alert.show("Please give at least 3 tags.", {type: 'info'});
      return;
    }

    const email = userData?.email;
    const userId = userData?.userId;
    const userFullname = userData?.userFullname;
    const userLevel = userData?.userLevel;
    const userBadges = userData?.userBadges;
    const userAccolades = userData?.userAccolades;

    var formData = new FormData();
    formData.append('draftPath', draftPath);
    formData.append('post_type', props.post_type); // post_type = 0 => article
    formData.append('authorEmail', email);
    formData.append('key', key);
    formData.append('articleTitle', articleTitle);
    formData.append('articleTags', articleTags);
    formData.append('authorId', userId);
    formData.append('authorFullname', userFullname);
    formData.append('authorLevel', userLevel);
    formData.append('authorBadges', userBadges);
    formData.append('authorAccolades', userAccolades);
    formData.append('email', email);
    formData.append('articleTitle', articleTitle);
    formData.append('articleTags', articleTags);
    formData.append('articleMatter', articleHtml);
    formData.append('articlesTableName', JsonData.ArticlesTableName);
    
    fetch('/api/submitPost.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
        // .then(response => response.text())
       .then(response => response.json())
      .then(data => {
        // window.alert(data);
        // Handle the data received from the PHP script
        console.log(data);

        if(data.status === "success") {
          alert.show(data.message, {type: 'success'});
          props.afterArticleSubmit(props.post_type);
        }
        else if(data.status === "error") {
          alert.show(data.message, {type: 'error'});
        }
        else if(data.status === "logout") {
          alert.show(data.message, {type: 'error'});
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show('Error fetching data: ' + error, {type: 'error'});
      });

  }


  const onTitleChange = (event) => {
    // Update the state
    setArticleTitle(event.target.value);
  };

  const onTagsChange = (event) => {
    // Update the state
    setArticleTags(event.target.value);
  };


  const handleArticleHtmlChange = (event) => {
    // get the value of the field
    const value = event; // ?.target?.value;
    //set it in the model
    setArticleHtml(value);
  };

  const handleDiscardButtonClick = () => {
    props.handleDiscardButtonClick();
  }

  return (
    <>
    <div>
      <p style={{textAlign:'left' }} >Create a new article about your code snippet or component. Explain the code or component in sufficient details.
      Use all the formatting options available in the toolbar below to write a very
        clear and concise article. Feel free to insert images and format text as you feel appropriate. However,
        it is strongly recommended that you follow the prescribed format in order to maintain uniformity of the
        articles across the board.
      </p>
    </div>
    <label for="title" style={{margin:'1rem'}} >Title: </label>
    <input
      id="title"
			type="text" placeholder="Title of the article..."
      value={articleTitle}
      onChange={onTitleChange}
			// onChange={onFileChange}
      // ref={inputFile}
      // style={{display:"none"}}
		/>
    <br/>
    <label for="tags" style={{margin:'1rem'}} >Tags: </label>
    <input
      id="tags"
			type="text" placeholder="Tags, separated by ','"
      value={articleTags}
      onChange={onTagsChange}
			// onChange={onFileChange}
      // ref={inputFile}
      // style={{display:"none"}}
		/>


    <AttachedFiles {...{ files : files, draftPath : draftPath, showUpload: true}} />
    <br/>
    <QuillEditor useMinHeight={true} HandleChange={handleArticleHtmlChange} Html={articleHtml} setArticleHtml={setArticleHtml} />

    <div style={{textAlign:'right'}}>
        {/* offers discard, submit and save buttons */}
        <button type="button" class="btn btn-warning" style={{margin:5}} onClick={handleDiscardButtonClick}>Discard</button>
        <button type="button" class="btn btn-primary" style={{margin:5}} onClick={handleSaveButtonClick} >Save</button>
        <button type="button" class="btn btn-success" style={{margin:5}} onClick={handleSubmitButtonClick} >Submit</button>
    </div>
    </>
  );
}

export default ArticleEditor;

