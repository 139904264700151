import * as React from "react";
import JsonData from '../specific/webinfo.json';

function HitCounter(props) {
    var formData = new FormData();
    var fn = JsonData.DomainShortName + ".hc";
    formData.append('filename', fn);

    fetch('/api/hitCounter.php', {
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    body: formData,
    })
    .then(response => response.text())
    .then(data => {
        console.log("Hit count incremented.");
    })
    .catch(error => {
        // Handle any errors here
        console.error("There was an error while incrementing hit counter for website: " + JsonData.DomainShortName);
    });

    return <></>
}

export default HitCounter;
