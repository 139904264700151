import React, { useState, useEffect } from 'react';
import QuillViewer from "./QuillViewer";
import AttachedFiles from "../Preview/AttachedFiles";
import CommentEditor from "./CommentEditor";
import RatingStars from "../RatingStars";
import { useAlert } from 'react-alert'
import JsonData from '../../specific/webinfo.json';
import Tags from '../Tags';

var authorIsViewer = false;

function ArticleViewer(props) {
  const [articleTitle, setArticleTitle] = useState(props.articleTitle);
  const [articleTags, setArticleTags] = useState(props.articleTags);
  const [articleHtml, setArticleHtml] = useState(props.articleHtml);
  const [files, setFiles] = useState([]);
  const [draftPath, setDraftPath] = useState("");
  const [userHasAlreadyCommented, setUserHasAlreadyCommented] = useState(false);
  const [userHasAlreadyRated, setUserHasAlreadyRated] = useState(false);
  const [commentTitle, setCommentTitle] = useState(props.commentTitle);
  const [commentHtml, setCommentHtml] = useState(props.commentHtml);
  const [authorProfile, setAuthorProfile] = useState("");
  const [reviewerCommentHtml, setReviewerCommentHtml] = useState("");
  const [reviewerApproves, setReviewerApproves] = useState(false);
  const [authorName, setAuthorName] = useState("");
  const [rating, setRating] = useState(0);
  const [nVotes, setNVotes] = useState(0);
  const [canUserRate, setCanUserRate] = useState(true);
  const [authorEmail, setAuthorEmail] = useState(true);
  const alert = useAlert();


  useEffect(() => {
    setArticleHtml(props.articleHtml);
  }, [props.articleHtml]);

  useEffect(() => {
    setAuthorEmail(props.authorEmail);
  }, [props.authorEmail]);

  useEffect(() => {
    setAuthorName(props.authorName);
  }, [props.authorName]);

  useEffect(() => {
    setRating(props.rating);
  }, [props.rating]);

  useEffect(() => {
    setNVotes(props.nVotes);
  }, [props.nVotes]);

  useEffect(() => {
    setAuthorProfile(props.authorProfile);
  }, [props.authorProfile]);

  useEffect(() => {
    setArticleTitle(props.articleTitle);
  }, [props.articleTitle]);

  useEffect(() => {
    setArticleTags(props.articleTags);
  }, [props.articleTags]);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  useEffect(() => {
    setDraftPath(props.draftPath);
  }, [props.draftPath]);

  useEffect(() => {
    setUserHasAlreadyCommented(props.userHasAlreadyCommented);
  }, [props.userHasAlreadyCommented]);

  useEffect(() => {
    setUserHasAlreadyRated(props.userHasAlreadyRated);
  }, [props.userHasAlreadyRated]);
  
  const isLoggedIn = sessionStorage.getItem("loggedIn");
 
  const HandleChange = () => {

  }
  
  const titleChanged = (value) => {
    setCommentTitle(value);
  }

  const commentHtmlChanged = (value) => {
    setCommentHtml(value);
  }

  const handleCommentSubmitButtonClick = () => {
    // alert(articleId);

    if(commentHtml === "<p></p>")
    {
      alert.show("Please enter a valid comment.", {type: 'error'});
      return;
    }

    // if(!window.confirm("Are you sure you want to submit the comment?")) return;

    var userData = JSON.parse(sessionStorage.getItem("userData"));
    var userId = "0"; // not logged in
    if(userData!== undefined || userData !== null) {
      userId = userData.userId;
    }

    var email = userData.email;
    var articleKey = props.articleKey;
    var post_type = props.post_type;
    var operation = 0; // because we want to add a comment (or reply). 1 if it is to be deleted -> to be done only by moderator
    var itemType = 0; // 0 = comment
    var userFullname = userData.userFullname;
    var text = commentHtml;
    var title = commentTitle;
    var articleId = props.articleKey;
    var commentId = "";
    var replyId = "";

    var formData = new FormData();

      formData.append('userId', userId); 
      formData.append('email', email);
      formData.append('articleKey', articleKey);
      formData.append('post_type', post_type);
      formData.append('operation', operation);
      formData.append('itemType', itemType);
      formData.append('userFullname', userFullname);
      formData.append('text', text);
      formData.append('title', title);
      formData.append('articleId', articleId);
      formData.append('commentId', commentId);
      formData.append('replyId', replyId);


      fetch('/api/updateCommentsAndReplies.php', {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        body: formData,
      })
          //  .then(response => response.text())
          .then(response => response.json())
         .then(data => {
        //  alert(data);  
            props.updateComments(data.data.comments);
              alert.show(data.message, {type: 'info'});
              setUserHasAlreadyCommented(true);
            

          console.log(data.message);
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          alert.show(error, {type: 'error'});
        });
  }
  
  const reviewrCommentChanged = (event) =>{
    setReviewerCommentHtml(event);
  }

  const onReviewerApprovalCheckChange = () => {
    setReviewerApproves (!reviewerApproves);
  }


  const handleReviewSubmitClick = (event) => {
    event?.preventDefault();
  
    // submit the review comments.
    var conf;
    if(reviewerApproves) {
      conf = window.confirm("You are APPROVING this article. Are you sure?");
    } else {
      conf = window.confirm("You are REJECTING this article. Are you sure?");
    }
    if(!conf) return;

    // submit the response!
    
    var userData = JSON.parse(sessionStorage.getItem("userData"));
   
    var email = userData.email;
    var articleKey = props.articleKey;

    var formData = new FormData();

      formData.append('articleKey', articleKey); 
      formData.append('reviewerEmail', email);
      formData.append('reviewerComments', reviewerCommentHtml);
      formData.append('articlesTableName', JsonData.ArticlesTableName);

      if(reviewerApproves) {
        formData.append('reviewerDecision', 1);
      } else {
        formData.append('reviewerDecision', 0);
      }
      

      fetch('/api/submitReviewerResponse.php', {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        body: formData,
      })
          // .then(response => response.text())
           .then(response => response.json())
         .then(data => {
            // alert(data);
          alert.show(data.message, {type: 'info'});
          props.refreshReviewersList();
          console.log(data.message);
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          alert.show(error, {type: 'error'});
        });
  }
  
  const onStarSelected = (value) => {

    var userData = JSON.parse(sessionStorage.getItem("userData"));
    var userId = "0"; // not logged in
    
    userId = userData?.userId;
    
    if(userId <= 0) {
      alert.show("You must be logged in to rate an article", {type: 'info'});
      return;
    }
    if(!sessionStorage.getItem("loggedIn")) {
      alert.show("You must be logged in to rate an article", {type: 'info'});
      return;
    }

    var conf = window.confirm('You are rating this article at ' + value + '. Do you wish to continue?');
    if(!conf) return;

    var email = userData.email;
    var articleKey = props.articleKey;
    var articlePath = props.draftPath;

    var formData = new FormData();
      formData.append('email', email);
      formData.append('articleKey', articleKey);
      formData.append('articlePath', articlePath);
      formData.append('value', value);

      fetch('/api/submitRating.php', {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        body: formData,
      })
        //  .then(response => response.text())
        .then(response => response.json())
        .then(data => {
          // alert(data);
          props.updateComments(data.data.comments);
          alert.show(data.message, {type: 'info'});
        
        console.log(data.message);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        alert.show(error, {type: 'error'});
      });
  }

  var userDataJson = sessionStorage.getItem("userData");
  var ud = JSON.parse(userDataJson);
  var e = "";
  e = ud?.email;
  if(e === undefined || e === "") {
    if(canUserRate) {
      setCanUserRate(false);
    }
  } else {
    if(e === authorEmail) {
      if(canUserRate) {
        setCanUserRate(false);
      } 
    }
  }

var loggedIn = sessionStorage.loggedIn;

loggedIn = (loggedIn === 'true');

const getStars = () => {
  let combo = (userHasAlreadyRated === true || canUserRate === false || loggedIn === false);

  let ratingText;
  if(nVotes === 1) {
    ratingText = rating + " [1 Vote]";
  } else {
    ratingText = rating + " [" + nVotes + " Votes]";
  }


      return (<> 
        <RatingStars value={rating} text={ratingText} editable={!combo} onStarSelected={onStarSelected} fontWeight={'bold'} />
      </>)
}

  var userData = JSON.parse(sessionStorage.getItem("userData"));
  var userEmail = ""; // not logged in
  userEmail = userData?.email;
  authorIsViewer = (userEmail === authorEmail);

  var tags = articleTags.split(",");

  return (    
    <>
    <div style={{marginLeft:'1rem'}}>
      <p style={{marginLeft:'0rem', marginTop:'1rem', marginBottom:'0rem', fontWeight:"bold"}}  id="title">{articleTitle}</p>
      <Tags values = {tags} />
      {/* <label for="tags" style={{marginTop:'0rem', marginBottom:'0rem', fontWeight:'bold'}} >Tags:</label>&nbsp;
      <span style={{margin:'0em', fontWeight:'bold'}}  id="tags">{articleTags}</span> */}
      

      <p style={{textAlign:"left"}}>{getStars()}</p>


      <p style={{fontWeight:'bold', margin:'0em'}}>-{authorName}</p>

      {
        files.length > 0 ?
        <>
          <AttachedFiles {...{ files : files, draftPath : draftPath, showUpload:false, articleKey:props.articleKey, post_type: props.post_type}} />
        </>
        :
        <></>
      }    
      <br/>
      <QuillViewer Html={articleHtml} HandleChange={HandleChange} />
      
      <p style={{fontWeight:'bold', margin:'0em'}}>Author Profile:</p>
      <QuillViewer Html={authorProfile} />
    </div>
    { 
      props.articlesForReview ?
      <>
        <div style={{marginLeft:'1em'}}>
        <p>Submit reviewer comments.</p>
        <CommentEditor hasTitle={false} commentHtmlChanged={reviewrCommentChanged} handleSubmitButtonClick={handleReviewSubmitClick}>
            <input type="checkbox" id="reviewerApproval" name="reviewerApproval" onChange={onReviewerApprovalCheckChange} />
            <label for="reviewerApproval">&nbsp; I approve the article for publishing!</label><br></br>
        </CommentEditor>
        </div>
      </>
      :
      <>
      { !userHasAlreadyCommented && isLoggedIn && !authorIsViewer ?
        
        <div style={{marginLeft:'1em'}}>
          <p><i>Give your comments...</i></p>
          <CommentEditor hasTitle={true} commentHtmlChanged={commentHtmlChanged} titleChanged={titleChanged} handleSubmitButtonClick={handleCommentSubmitButtonClick} />
        </div>
      : <></>
      }
      </>
    }
    </>
  )
}

export default ArticleViewer;
