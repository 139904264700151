import React, { useState, useEffect } from 'react';
import QuillCommentEditor from "./QuillCommentEditor";

function CommentEditor(props) {
  const [commentHtml, setCommentHtml] = useState("");
  const [hasTitle, setHasTitle] = useState(false);

  useEffect(() => {
    setCommentHtml(props.commentHtml);
  }, [props.commentHtml]);
 
  
  useEffect(()=>{
    setHasTitle(props.hasTitle);
  }, [props.hasTitle]);
  


  const handleSubmitButtonClick = (event) => {
    event.preventDefault();
    if(window.confirm("Are you sure you want to submit the comment?")) {
      props.handleSubmitButtonClick();
    }
  }

  const handleDiscardButtonClick = (event) => {
    props.onDiscardButtonClick();
  }
  
  const handleHtmlChanged = (event) => {
    
    const value = event; // ?.target?.value;
    //set it in the model
    setCommentHtml(value);
    props.commentHtmlChanged(value);
  };
 
  const handleTitleChanged = (event) => {
    // get the value of the field
    const value = event?.target?.value;
    //set it in the model
    // setTitle(value);
    props.titleChanged(value);
  };
 
  return  <>
      {hasTitle ? 
        <div>
          <label for="title" style={{margin:'0rem'}} >Title: </label>
          <input id="title" type="text" style={{width:"100%"}} placeholder="Title" onChange={handleTitleChanged} />
      </div>

        :
      <></>
      }
      
    <QuillCommentEditor  HandleChange={handleHtmlChanged} Html={commentHtml} />

      <div style={{textAlign:'right'}}>
      {props.children}
        {
          props.showDiscardButton ? 
            <button type="button" class="btn btn-warning" style={{margin:5}} onClick={handleDiscardButtonClick} >Discard</button>
          :
            <></>
        }
        <button type="button" class="btn btn-success" style={{margin:5}} onClick={handleSubmitButtonClick} >Submit</button>
      </div>

    </>
}

export default CommentEditor;

