import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useSearchParams } from 'react-router-dom';

function ArticlePreview(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [wholeAreaClickable, setWholeAreaClickable] = useState(true);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const username = userData?.username;

  const articleKey = props.articleKey;

  const title = props.title;
  const author = props.author;
  const rating = props.rating;
  const dateSubmitted = props.dateSubmitted;
  const nVotes = props.nVotes;
  const articleId = props.articleId;
  const authorId = props.authorId;
  
  const views = props.views;
  const tags = props.tags;
  const path = props.path;
  const post_type = props.post_type;

  useEffect(() => {
    setWholeAreaClickable(props.wholeAreaClickable);
  }, [props.wholeAreaClickable]);

const handleArticleClicked = (event) => {
  event.stopPropagation();
  event.preventDefault();
 
  event?.preventDefault();
  if(post_type === 0) {
    setSearchParams({ articleId: articleKey });
  } else {
    setSearchParams({ questionId: articleKey });
  }
  if(articleKey !== "") {
    props.handleArticlePreviewClicked();
  }
}

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

return (
    <>
    <div>
      <div class="card shadow">
        {/* <div class="card-body m-0 p-0"> */}
        <div class="card-body m-0 pt-1">
            <p class="card-text p-0 m-0" style={{textAlign:"left"}}>
                {
                  wholeAreaClickable ?
                  <a href="" onClick={handleArticleClicked} class={"stretched-link"} style={{maxWidth:"85%", fontWeight:"bold", fontStyle:"italic"}}>{title}</a>  
                  :
                  <a href="" onClick={handleArticleClicked} style={{maxWidth:"85%", fontWeight:"bold", fontStyle:"italic"}}>{title}</a>
                }

                <span style={{float:"right"}}><ReactStars count={10} onChange={ratingChanged} size={24} value={rating} edit={false} isHalf={true} activeColor="blue" color={"powderblue"} /></span>

            </p>
            <p class="card-text p-0 m-0" style={{textAlign:"left", fontWeight:"bold"}} >-{author}</p>
            <p class="card-text p-0 m-0" style={{textAlign:"left"}} >&nbsp;&nbsp;Submitted: {dateSubmitted}</p>
        </div>
      </div>
    </div>
    </>    
  );
  
}

export default ArticlePreview;

