import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useAlert } from 'react-alert'

function Voter(props) {
  const [vote, setVote] = useState(0);
  const [commentId, setCommentId] = useState("");
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);
  const alert = useAlert();

  const isLoggedIn = sessionStorage.getItem("loggedIn");
  

  useEffect(() => {
    setVote(props.vote);
  }, [props.vote]);

  useEffect(() => {
    setCommentId(props.commentId);
  }, [props.commentId]);
  

  useEffect(() => {
    setPositiveCount(props.positiveCount);
  }, [props.positiveCount]);
  

  useEffect(() => {
    setNegativeCount(props.negativeCount);
  }, [props.negativeCount]);
  

  const addVote = (v) => {
    if(!isLoggedIn) {
        alert.show("You can vote only if you are logged in.", {type: 'error'});
        return;
    }

    if(vote !== 0) {
        alert.show("You can vote only once..", {type: 'error'});
        return;
    }

    setVote(v);
    props.onSubmitVote(commentId, v);
  }

  var positiveText = "0";
  if(positiveCount > 0) positiveText = "+" + positiveCount.toString();
  
  var negativeText = "0";
  if(negativeCount < 0) negativeText = negativeCount.toString();


  return <>
        {
            vote === 0 ?
            <>
                <i class="bi bi-hand-thumbs-up" onClick={(event)=>{event.preventDefault(); addVote(1); }} style={{cursor:'pointer'}} ></i>
                {positiveText}
                &nbsp;
                <i class="bi bi-hand-thumbs-down" onClick={(event)=>{event.preventDefault(); addVote(-1); }} style={{cursor:'pointer'}} ></i>
                {negativeText}
            </>
            :
            <>
                {
                    vote === 1 ?
                    <>
                        <i class="bi bi-hand-thumbs-up-fill"></i>
                        {positiveText}
                        &nbsp;
                        <i class="bi bi-hand-thumbs-down"></i>
                        {negativeText}
                </>
                    :
                    <>
                        <i class="bi bi-hand-thumbs-up"></i>
                        {positiveText}
                        &nbsp;
                        <i class="bi bi-hand-thumbs-down-fill"></i>
                        {negativeText}
                    </>
                }
            </>
        }
   </>
}

export default Voter;
