import React, {useCallback, useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

function QuillEditor(props) {
  const [editorValue, setEditorValue] = useState( props.Html);
  const quillRef = useRef();
  const [useMinHeight, setUseMinHeight] = useState(false);

  useEffect(() => {
    setEditorValue(props.Html);
  }, [props.Html]);
  useEffect(() => {
    setUseMinHeight(props.useMinHeight);
  }, [props.useMinHeight]);
  


  const handleOnChange = (value) =>{
    setEditorValue(value);
    props.HandleChange(value);
  };
  
  const imageHandler = useCallback(() => {
    // Create an input element of type 'file'
    alert("hey bt");
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // When a file is selected
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();

      // Read the selected file as a data URL
      reader.onload = () => {
        const imageUrl = reader.result;
        const quillEditor = quillRef.current.getEditor();

        // Get the current selection range and insert the image at that index
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
      };

      reader.readAsDataURL(file);
    };
  }, []);
  
  var toolbarOptions = {
     container: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['link', 'image', 'blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean'] // remove formatting button
  ],
handlers: {  
  image : imageHandler
  }
};
 
  var quill;
  if(useMinHeight) {
    quill = <ReactQuill
      className={"minHeightQuill"}
      placeholder='Write your awesome article...'
      value={editorValue}
      onChange= {(value) => handleOnChange(value)}
      modules={{
      toolbar: toolbarOptions
    }}
    theme="snow"
    ref={quillRef}
  />;
} else {
    quill = <ReactQuill
      placeholder='Write your awesome article...'
      value={editorValue}
      onChange= {(value) => handleOnChange(value)}
      modules={{
      toolbar: toolbarOptions
    }}
    theme="snow"
    ref={quillRef}
  />;  
}


  return ( quill );
}


// // For preview
// <ReactQuill
//    value={this.state.content}
//    readOnly={true}
//    theme={"bubble"}
// />

export default QuillEditor;
