import React, { useState } from "react";

function ContactModal ({ onClose, children }) {
  const [emailId, setEmailId] = useState("");
  const [subject, setSubject] = useState("");
  const [emailMatter, setEmailMatter] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const handleEmailChange = (event) => {
    event?.preventDefault();
    setEmailId(event.target.value);
  };

  const handleSubjectChange = (event) => {
    event?.preventDefault();
    setSubject(event.target.value);
  };

  const handleEmailMatterChange = (event) => {
    event?.preventDefault();
    setEmailMatter(event.target.value);
  };
  
  const onCancelClick = (event) => {
    event.preventDefault();
    onClose();
  }

  const SendEmail = () => {

    var formData = new FormData();
    formData.append('toEmail', emailId);
    formData.append('emailSubject', subject);
    formData.append('emailMatter', emailMatter);



    fetch('/api/contactusSendEmail.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
    //  .then(response => response.text())
     .then(response => response.json())
      .then(data => {
        console.log(data);
        
        if(data.status === "success") {
          setShowSuccess(true);
        }
        else if(data.status === "error") {
          window.alert(data.message);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        window.alert(error);
      });

  }

  const onSendMessageClick = (event) =>{
    event?.preventDefault();
    SendEmail();
  }

  const getSuccessMessage = () => {
    return (
      <div id="contactModal">
        <div class="container">
          <h2>Thank You</h2>
          <form class="form-horizontal" >
            <div class="form-group">
              <p>Thank you for contacting us. We will promptly respond to your message if required.</p>
            </div>
            <div class="form-group">
              <p>Best Wishes!</p>
            </div>
            <br />
            <div class="form-group">
              <p><b>- ConceptsOnFire.com team</b></p>
            </div>
            <div class="form-group center">
                <button class="btn btn-success" onClick={onCancelClick}>Close</button>
            </div>
          </form>
        </div>
      </div>
    ) 
  }

  const getContactForm = () => {
    return (
      <div id="contactModal">
        <div class="container">
          <h2>Contact Us</h2>
          <form class="form-horizontal" >
            <div class="form-group">
              <div class="input-group mb-3">
                <span class="input-group-text">Email: </span>
                <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" onChange={handleEmailChange } />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group mb-3">
                <span class="input-group-text">Subject: </span>
                <input type="text" class="form-control" id="subject" placeholder="Enter subject" name="subject" onChange={handleSubjectChange} />
              </div>
            </div>
            <br />
            <div class="form-group">
              <span class="input-group-text">Matter: </span>
              <div class="input-group mb-3">
                <textarea class="form-control" id="matter" placeholder="Email matter" name="emailMatter" onChange={handleEmailMatterChange} 
                style={{minWidth:'30em', minHeight:'10em'}} />
              </div>
            </div>
            <br />
            <div class="form-group center">
                <button class="btn btn-warning" onClick={onCancelClick}>Cancel</button>
                &nbsp;
                <button onClick={onSendMessageClick } class="btn btn-primary btn-default">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    )    
  }




  return (
    <>
    { showSuccess ? getSuccessMessage() : getContactForm() }
    </>
  )
}

export default ContactModal;
