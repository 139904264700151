import { useState } from "react";
import * as React from "react";
import { useEffect } from "react";
import MainArea from "./MainArea";
import LayoutElement from './Layout/LayoutElement/LayoutElement';
import { useSearchParams } from 'react-router-dom';
import HeaderLinks from './HeaderLinks';
import AdHostVertical from './AdHosts/AdHostVertical';
import { useAlert } from 'react-alert'
import JsonData from '../specific/webinfo.json';

// based on the variable "command" which should be in props,

var attachmentFiles = [];
var selectedPostType = 0;

function Article(props) {
  const [selectedDraftPath, setSelectedDraftPath] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const email = userData?.email;
  const [articles, setArticles] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [articleHtml, setArticleHtml] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleTags, setArticleTags] = useState("");
  const [selectedArticleKey, setSelectedArticleKey] = useState("");
  const [selAuthorId, setSelAuthorId] = useState(0);
  const [selPosttype, setSelPosttype] = useState(0);
  const [mainAreaCommand, setMainAreaCommand] = useState("");
  const [articlesForReview, setArticlesForReview] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [allArticleList, setAllArticleList] = useState([]);
  const alert = useAlert();

  // useEffect(() => {
  //   attachmentFiles = props.attachmentFiles;
  //   // setAttachmentFiles(props.attachmentFiles);
  // }, [props.attachmentFiles]);

  useEffect(() => {
    // showAllArticles();
  }, []);


  useEffect(() => {
    setMainAreaCommand(props.mainAreaCommand);
  }, [props.mainAreaCommand]);
  
  useEffect(() => {
    setSelPosttype(props.selPostType);
  }, [props.selPostType]);
  
  
  useEffect(() => {
    setSelAuthorId(props.selAuthorId);
  }, [props.selAuthorId]);
  

  const handleCreateNewArticleDraftRequest = (email,  draftArticlePath, files, selPostType) => {
    selectedPostType = selPostType;
    
    attachmentFiles = files;
    setSelectedDraftPath(draftArticlePath);
    setArticleTitle("");
    setArticleTags("");
    setArticleHtml("");
    
    props.selectMainAreaCommand('showArticleEditor');
  }

  
  const onDeleteDraftArticleClicked = (index, post_type) => {
    var art;
    if(post_type === 0) {
      art = articles[index];
    } else {
      art = questions[index];
    }
     
    if(art === undefined || art === "") {
      getDraftList();
    }
    var path = art.Path;
    // var post_type = a.Post_Type;
    // setMainareaCommand(mainareaCommand);
    // getDraft(path);
    deleteDraft(path, post_type);
    // getDraftList();
  }

  const onVerifyUserEmailId = () => {
    if (searchParams.has('userid')) {
      searchParams.delete('userid');
      setSearchParams(searchParams);
    }
    if (searchParams.has('token')) {
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
    
    props.selectMainAreaCommand('showMyArticlesList');
  }

  const onDraftClicked = (index, post_type) => {
    
    var a;
    if(post_type === 0) {
      a = articles[index];
    } else {
      a = questions[index];
    }

    var path = a.Path;
    // var post_type = a.Post_Type;
    props.selectMainAreaCommand('showArticleEditor');
    selectedPostType = post_type;
    getDraft(path, post_type);
  }

  
  const getDraftList = () => {
    
    var formData = new FormData();
    formData.append('email', email);
    
    fetch('/api/getDraftList.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData,
    })
    //  .then(response => response.text())
     .then(response => response.json())
      .then(data => {
        var draftListJson = data.data.drafts;
        var draftList = JSON.parse(draftListJson);
        var articleListJson = draftList[0];
        var questionListJson = draftList[1];
        
        var articleList = JSON.parse(articleListJson);
        var questionList = JSON.parse(questionListJson);

        var _articles = [];
        for(var i = 0; i < articleList.length; i++) {
          _articles.push(JSON.parse(articleList[i]));
        }

        var _questions = [];
        for(i = 0; i < questionList.length; i++) {
          _questions.push(JSON.parse(questionList[i]));
        }

        setArticles(_articles);
        setQuestions(_questions);        
 
        console.log(data.message);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        // alert(error);
        alert.show(error, {type: 'error'});
      });
  };

  const deleteDraft = (path, post_type) => {
    var formData = new FormData();
    formData.append('draftPath', path);
    formData.append('post_type', post_type);

    fetch('/api/deleteDraft.php', {
      method: 'POST',
      body: formData,
    })
    //  .then(response => response.text())
     .then(response => response.json())
      .then(data => {
      //  alert(data); // if we use response.text()
        //  alert(data.message);
        if(data.status === "success") {
          getDraftList();
        } else {
          // alert(data.message);
          alert.show(data.message, {type: 'error'});
        }
        console.log(data.message);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        // alert(error);
        alert.show(error, {type: 'error'});
      });
  };


  const getDraft = (path, post_type) => {
    var formData = new FormData();
    formData.append('draftPath', path);
    formData.append('post_type', post_type);
    
    fetch('/api/getDraft.php', {
      method: 'POST',
      body: formData,
    })
    //  .then(response => response.text())
     .then(response => response.json())
      .then(data => {
      //  alert(data); // if we use response.text()
        //  alert(data.message);
        
        var json = data.data.article;
        var _art = JSON.parse(json);
        // setDraftArticle(_art);

        // setAttachmentFiles(data.data.files);
        attachmentFiles = data.data.files;
        setSelectedDraftPath(_art.draftArticlePath);
        setArticleHtml(_art.Matter);
        setArticleTags(_art.Tags);
        setArticleTitle(_art.Title);
        setSelectedArticleKey(_art.Key);
        setSelectedDraftPath(path);
        console.log(data.message);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        // alert(error);
        alert.show(error, {type: 'error'});
      });
  };

const handleSaveArticleButtonClick = (key, path) => {
  setSelectedDraftPath(path);
  setSelectedArticleKey(key);
  getDraftList();
}

const handleOnMyArticlesClick = () =>{
  props.selectMainAreaCommand('showMyArticlesList');
}
const handleDiscardButtonClick = () => {
  props.selectMainAreaCommand("showMyArticlesList");
}

const handleChangeViewItem = (viewItem) => {
  props.selectMainAreaCommand(viewItem);
}

const handleArticlePreviewClicked = () => {
  props.selectMainAreaCommand('showArticleOrQuestion');
}


const getHeaderLinks = () => {
  var linkTexts;
  var linkActions;

  var udj = sessionStorage.getItem("userData");
  var ud = JSON.parse(udj);
  var uid = 0;
  var isEditor = false;
  var isReviewer = false;
  if(ud !== null) {
    uid = ud?.userId;
    isEditor = false;
    if(ud.isEditor > 0 || ud.isEditor === true) isEditor = true;

    isReviewer = false;
    if(ud.isReviewer > 0 || ud.isReviewer === true) 
    {
      isReviewer = true;
    }
  }

  var isUserLoggedIn = false;
  if(uid > 0) {
    isUserLoggedIn = true;
  }

  if(isUserLoggedIn) {
    var userDataJson = sessionStorage.getItem("userData");
    ud = JSON.parse(userDataJson);
    var userId = ud.userId;

    linkTexts = ['All Components', 'My Components', 'Add'];
    linkActions = 
    [
      ()=>{ // All Articles
        setMainAreaCommand('showMyArticlesList'); 
        setArticlesForReview(false);
        getArticleList (0, 0, 0);
      },
      ()=>{ // My Articles
        setMainAreaCommand('showMyArticlesList'); 
        setArticlesForReview(false);
        getArticleList (userId, 0, 0);
      },
      ()=>{
        getDraftList();
        setMainAreaCommand('showDashboard'); 
      }
    ];

    isEditor = false; // we fix isEditor to false because we have not defined editor responsibilities/functions as of now.
    if(isEditor) {
      linkTexts.push("Edit Components");
      linkActions.push(
        ()=>{
          setMainAreaCommand('showMyArticlesList'); 
          setArticlesForReview(false);
          getArticleList (0, 0, 0); // editor needs to be set here
        }
      );
    }
     
    if(isReviewer) {
      linkTexts.push("Review Components");
      linkActions.push(
        ()=>{
          setMainAreaCommand('showMyArticlesList'); 
          setArticlesForReview(true);
          getArticleList (0, 0, 1);
        }
      );
    }

  } else {
    linkTexts = ['All Components'];
    linkActions = 
    [
      ()=>{ // All Articles
        setMainAreaCommand('showMyArticlesList'); 
        setArticlesForReview(false);
        getArticleList (0, 0, 0);
      }
    ];
  }
  
  return [linkTexts, linkActions];
}

const refreshReviewersList = () => {
  setMainAreaCommand('showMyArticlesList'); 
  setArticlesForReview(true);
  getArticleList (0, 0, 1);
}


const getArticleList = (authorId, postType, forReview) => {
  var maxItems = 10;
  var fromIndex = 0;
  var minRating = 0; // Bhairav, remember to set this to user preferred value
  var orderByRating = 0; // false
  var order = 0; // decending order / newest to oldest
  var getTotalRowCount = 1;  
  var onlyForEditing = 0;

  var formData = new FormData();
  formData.append('post_type', postType); // Article or Question
  formData.append('authorId', authorId);
  formData.append('fromIndex', fromIndex);
  formData.append('maxItems', maxItems);
  formData.append('query', searchString);
  formData.append('minRating', minRating);
  formData.append('orderByRating', orderByRating);
  formData.append('order', order);
  formData.append('getTotalRowCount', getTotalRowCount);
  formData.append('articlesTableName', JsonData.ArticlesTableName);

  formData.append('getUnreviewedPosts', forReview);
  formData.append('getUneditedPosts', onlyForEditing);
  

  fetch('/api/getPostList.php', {
    method: 'POST',
    body: formData,
  })
     .then(response => response.text())
    //  .then(response => response.json())
    .then(data => {
      console.log(data);
      // window.alert(data); // if we use response.text()
      data = JSON.parse(data);
      //  alert(data.message);
      var nDisplayArticles = data.data.nDisplayArticles;
      
      var artsJson = data.data.articles;
      var allartsJson = data.data.allarticles;
      var nAllArticles = data.data.nallarticles;
      var arts = JSON.parse(artsJson);
      var allarts = JSON.parse(allartsJson);

      var a = [];
      for(var i = 0; i < nDisplayArticles; i++) {
        var obj = JSON.parse(arts[i]);
        a.push(obj);
      }
      // setNArticles(nArticles);
      setArticleList(a);

      var aAll = [];
      for(i = 0; i < nAllArticles; i++) {
        obj = JSON.parse(allarts[i]);
        aAll.push(obj);
      }

      // setNAllArticles(nAllArticles);
      setAllArticleList(aAll);
      // setAllArticles(aAll);

      // alert(nArticles);
      console.log(data.message);
    })
    .catch(error => {
      // Handle any errors here
      console.error(error);
      // alert(error);
      alert.show(error, {type: 'error'});
    });
};

const onSetSearchString = (searchString) => {
  setSearchString(searchString);
}



var headerButtons = getHeaderLinks();
var linkTexts = headerButtons[0];
var linkActions = headerButtons[1];



  return (<>
    <br></br>
    <HeaderLinks linkTexts={linkTexts} linkActions={linkActions} setSearchString={onSetSearchString}/>
    <LayoutElement ss = {[
      {Columns: 12, Element: <MainArea 
            command={mainAreaCommand} 
            attachmentFiles={attachmentFiles} 
            selectedDraftPath={selectedDraftPath}
            articleHtml={articleHtml}
            articleTitle={articleTitle}
            articleTags={articleTags}
            handleArticlePreviewClicked={handleArticlePreviewClicked}
            handleSaveArticleButtonClick={handleSaveArticleButtonClick}
            setArticleTitle={setArticleTitle}
            setArticleTags={setArticleTags}
            setArticleHtml={setArticleHtml}
            selectedArticleKey={selectedArticleKey}
            post_type={selectedPostType}
            handleDiscardButtonClick={handleDiscardButtonClick}
            handleChangeViewItem={handleChangeViewItem}
            onVerifyUserEmailId={onVerifyUserEmailId}
            selectMainAreaCommand={props.selectMainAreaCommand}
            wholeAreaClickable={props.wholeAreaClickable}
            searchString={searchString}
            onLogout={props.onLogout}
            reviewerRequestsEmails={props.reviewerRequestsEmails} 
            reviewerRequestsDetails={props.reviewerRequestsDetails}
            selAuthorId={selAuthorId}
            selPosttype={selPosttype}
            articlesForReview={articlesForReview}
            articles={articles}
            questions={questions}
            handleCreateNewArticleDraftRequest={handleCreateNewArticleDraftRequest}
            handleCreateDraftArticle={props.handleCreateDraftArticle}
            handleOnMyArticlesClick={handleOnMyArticlesClick}
            getDraftList={getDraftList}
            onDraftClicked={onDraftClicked}
            onDeleteDraftArticleClicked={onDeleteDraftArticleClicked}
            articleList={articleList}
            allArticleList={allArticleList}
            refreshReviewersList={refreshReviewersList}
          
      /> },
    ]} />
  </>
  );
  
}

export default Article;
