import React, { useState, useEffect } from 'react';

var isHovering = false;
function RatingStars(props) {
  const [value, setValue] = useState(0);
  const [tempValue, setTempValue] = useState(0);
  const [text, setText] = useState("");
  const [backColor, setBackColor] = useState("powderblue");
  const [color, setColor] = useState("blue");
  const [editable, setEditable] = useState(true);
  const [fontWeight, setFontWeight] = useState('normal');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  useEffect(() => {
    setBackColor(props.backColor);
  }, [props.backColor]);

  useEffect(() => {
    setEditable(props.editable);
  }, [props.editable]);

  useEffect(() => {
    setFontWeight(props.fontWeight);
  }, [props.fontWeight]);


  const onHover = (index) => {
    if(editable) {
        isHovering = true;
        setTempValue(index);
    }
  }

  const onStarSelected = (value) => {
    if(editable) {
      props.onStarSelected(value);
    }
  }
  
  const getStar = (index) =>{
    console.log("isHovering = " + isHovering);
    console.log("tempValue = " + tempValue);
    var v = value;
    if(isHovering) {
        v = tempValue;
    }

    if(index <= v){
        return (<i class="bi bi-star-fill" style={{color:color}} onMouseOver={()=>{onHover(index)}} onClick={()=>{onStarSelected(index)}}></i>)
    } else {
        return(<i class="bi bi-star-fill" style={{color:backColor}} onMouseOver={()=>{onHover(index)}} onClick={()=>{onStarSelected(index)}}></i>)
    }
  }

  const getStars = () => {
    if(editable) {
        return(<p style={{fontWeight:fontWeight}}>
            {getStar(1)}
            {getStar(2)}
            {getStar(3)}
            {getStar(4)}
            {getStar(5)}
            {getStar(6)}
            {getStar(7)}
            {getStar(8)}
            {getStar(9)}
            {getStar(10)}
            &nbsp;
            {text}
        </p>)
    } else {
        return(<p style={{fontWeight:fontWeight}}>
                {getStar(1)}
                {getStar(2)}
                {getStar(3)}
                {getStar(4)}
                {getStar(5)}
                {getStar(6)}
                {getStar(7)}
                {getStar(8)}
                {getStar(9)}
                {getStar(10)}
                &nbsp;
                {text}
            </p>)
    }
  }


  return (getStars())
        
}

export default RatingStars;
