import React, { useState } from "react";

function LoginModal ({ onClose, onLogin, onRegister, children }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRegistrationClicked = (event) => {
    event?.preventDefault();
    onRegister();
  }

  const onCancelClick = (event) => {
    event.preventDefault();
    onClose();
  }

  const onLoginClick = (event) => {
    event.preventDefault();

    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    // credentials: 'include',

    fetch('/api/login.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
    // .then(response => response.text())
     .then(response => response.json())
      .then(data => {
        // Handle the data received from the PHP script
        console.log(data);
        
        if(data.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("userData", JSON.stringify(data.data));

          if(rememberMe) {
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("userData", JSON.stringify(data.data));
          }
          console.log("about to call onLogin()");
          onLogin();
          console.log("back from onLogin()");
        }
        else if(data.status === "error") {
          window.alert(data.message);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        window.alert(error);
      });
    }

    const onForgotPasswordClicked = (event) => {
      event?.preventDefault();
      setShowForgotPassword(true);
    }

  const handleVerificationCodeChange = (event) => {
    event?.preventDefault();
    setVerificationCode(event.target.value);
  }

  const onSendCode = (event) => {
    event?.preventDefault();

    var formData = new FormData();
    formData.append('email', email);

    fetch('/api/sendRetrievePasswordCode.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
    // .then(response => response.text())
     .then(response => response.json())
      .then(data => {
        // Handle the data received from the PHP script
        console.log(data);
        
        if(data.status === "success") {
          window.alert(data.message);
          console.log("sent a retrieval code to email id provided");
        }
        else if(data.status === "error") {
          window.alert(data.message);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        window.alert(error);
      });

  }

  const onVerifyCode = (event) => {
    event?.preventDefault();

    var formData = new FormData();
    formData.append('email', email);
    formData.append('verificationCode', verificationCode);

    fetch('/api/verifyCodeAndSendTempPassword.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
    //  .then(response => response.text())
      .then(response => response.json())
      .then(data => {
        // Handle the data received from the PHP script
        console.log(data);
        
        if(data.status === "success") {
          window.alert(data.message);
          console.log("sent a temporary password to the user");
          onClose();
        }
        else if(data.status === "error") {
          window.alert(data.message);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        window.alert("there was an error: " + error);
      });


  }

  const onBackToLoginControls = (event) => {
    event?.preventDefault();
    event.stopPropagation();
    setShowForgotPassword(false);
  }

  const getForgotPasswordControls = () => {
    return(
      <div id="loginModal">
      <div class="container">
        <h2>Retrieve Password</h2>
        <form class="form-horizontal" action="">
          <div class="form-group">
            <div class="input-group mb-3">
              <span class="input-group-text">Email: </span>
              <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" onChange={handleEmailChange} />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group mb-3">
              <p>We will send you a code to the email id specified. Please enter that code to get a temporary password.</p>
              <button class="btn btn-primary" onClick={onSendCode}>Send Code</button>
              <input type="text" class="form-control" id="verificationCode" placeholder="Enter code" name="verificationCode" onChange={handleVerificationCodeChange} />
              <button class="btn btn-primary" onClick={onVerifyCode}>Verify</button>
            </div>
          </div>
          <br />
          <div class="form-group">        
              <button class="btn btn-warning" onClick={onBackToLoginControls}>Back</button>
          </div>
        </form>
      </div>
    </div>
    )
  }

  const getLoginControls = () => {
    return(
      <div id="loginModal">
      <div class="container">
        <h2>Log In</h2>
        <form class="form-horizontal" >
          <div class="form-group">
            <div class="input-group mb-3">
              <span class="input-group-text">Email: </span>
              <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" onChange={handleEmailChange} />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group mb-3">
              <span class="input-group-text">Password: </span>
              <input type="password" class="form-control" id="pwd" placeholder="Enter password" name="pwd" onChange={handlePasswordChange} />
            </div>
          </div>
          <br />
          <div class="form-group">        
              <button class="btn btn-warning" onClick={onCancelClick}>Cancel</button>
              &nbsp;
              <button onClick={onLoginClick} class="btn btn-primary btn-default">Log In</button>
          </div>
          <br />
          <div>
            <p style={{margin:"0em"}} >Don't have an account? <button type="button" class="btn btn-link" onClick={handleRegistrationClicked}>Sign up</button>. It's quick and free.</p>
            <p>Forgot password? <button type="button" class="btn btn-link" onClick={onForgotPasswordClicked}>Retrieve Password</button>.</p>
          </div>
        </form>
      </div>
    </div>
    )
  }

  return (
    <>
      {showForgotPassword ? getForgotPasswordControls() : getLoginControls()} 
    </>
  )
}

export default LoginModal;
