import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

function QuillViewer(props) {
  const [editorValue, setEditorValue] = useState( props.Html !== undefined ? props.Html : '');
  const [placeholder, setPlaceholder] = useState("");
  const quillRef = useRef();

  useEffect(() => {
    setEditorValue(props.Html);
  }, [props.Html]);
  
  useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [props.placeholder]);

  
  const handleOnChange = (value) =>{
    setEditorValue(value);
    props.HandleChange(value);
  };
  

  
  
  
  var quill = <ReactQuill
    placeholder={placeholder}
    value={editorValue}
    readOnly={true}
    onChange= {(value) => handleOnChange(value)}
    theme="bubble"
    ref={quillRef}
  />;

  return ( quill );
}


export default QuillViewer;
