import React, { useState, useEffect } from 'react';
import QuillEditor from "./QuillEditor";

function CollapsableEditor(props) {
  const [commentHtml, setCommentHtml] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [mainText, setMainText] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setCommentHtml(props.commentHtml);
  }, [props.commentHtml]);
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    setMainText(props.mainText);
  }, [props.mainText]);
  useEffect(() => {
    setDescription(props.description);
  }, [props.description]);


  const handlecommentHtmlChange = (event) => {
    setCommentHtml(event);
  }
 
  const onClickSubmit = (event) => {
    event?.preventDefault();
    props.onClickSubmit(commentHtml);
  }
  const onMainTextClick = (event) => {
    event?.preventDefault();
    setIsOpen(true);
  }
  const onClickClose = (event) => {
    event?.preventDefault();
    setIsOpen(false);
  }

  return (
    <>
    <button type="button" class="btn btn-link"onClick={onMainTextClick}>{mainText}</button>
    {
    isOpen ?
    <>
        <div>
            <p style={{textAlign:'left', margin:'0em'}}>{description}</p>
        </div>
        <div>
            <QuillEditor id='quillEditorComment' HandleChange={handlecommentHtmlChange} Html={commentHtml} />
        </div>
        <div style={{textAlign:'right'}}>
            <button type="button" class="btn btn-info" onClick={onClickClose} >Close</button>
                &nbsp;
            <button type="button" class="btn btn-primary" onClick={onClickSubmit} >Submit</button>
        </div>
    </>
    :
        <></>
    }
  </>)
}

export default CollapsableEditor;

