import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ArticleEditor from "./Quill/ArticleEditor";
import ArticleViewer from "./Quill/ArticleViewer";
import ArticleList from "./ArticleList";
import { useLocation } from 'react-router-dom';
import CommentsViewer from "./CommentsViewer";
import ProfileEditor from "./Quill/ProfileEditor";
import QuillViewer from "./Quill/QuillViewer";
import Dashboard from './Dashboard';
import { useAlert } from 'react-alert';
import { useSearchParams } from 'react-router-dom';
import JsonData from '../specific/webinfo.json';
import AdHostHorizontal from './AdHosts/AdHostHorizontal';

// based on the variable "command" which should be in props,
var attachmentFiles = [];
var ignoreReload = false;
var articlePath = "";

function MainArea(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [postArticle, setPostArticle] = React.useState("");
  const [postComments, setPostComments] = React.useState("");
  const [command, setCommand] = React.useState("");
  const [selectedDraftPath, setSelectedDraftPath] = useState("");
  const [articleHtml, setArticleHtml] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [articleTags, setArticleTags] = useState("");
  const [userHasAlreadyCommented, setUserHasAlreadyCommented] = useState(false);
  const [userHasAlreadyRated, setUserHasAlreadyRated] = useState(false);
  const [articleAuthorProfile, setArticleAuthorProfile] = useState("");
  const [selAuthorId, setSelAuthorId] = useState(0);
  const [selPosttype, setSelPosttype] = useState(0);
  const [reloadArticleList, setReloadArticleList] = useState(1);
  const [onlyForEditing, setOnlyForEditing] = useState(false);
  const [onlyForReview, setOnlyForReview] = useState(false);
  const [articlesForReview, setArticlesForReview] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [reviewerRequestsEmails, setReviewerRequestsEmails] = useState([]);
  const [reviewerRequestsDetails, setReviewerRequestsDetails] = useState([]);
  const [authorEmail, setAuthorEmail] = useState("");
  const [articleRating, setArticleRating] = useState("");
  const [articleNVotes, setArticleNVotes] = useState("");
  const [articleList, setArticleList] = useState([]);
  const [allArticleList, setAllArticleList] = useState([]);
  const alert = useAlert();

  
  useEffect(() => {
    setAllArticleList(props.allArticleList);
  }, [props.allArticleList]);

  useEffect(() => {
    setOnlyForReview(props.onlyForReview);
  }, [props.onlyForReview]);
  useEffect(() => {
    setOnlyForEditing(props.onlyForEditing);
  }, [props.onlyForEditing]);

  useEffect(() => {
    setArticleList(props.articleList);
  }, [props.articleList]);

  useEffect(() => {
    setReloadArticleList(props.reloadArticleList);
  }, [props.reloadArticleList]);

  useEffect(() => {
    setCommand(props.command);
  }, [props.command]);

  useEffect(() => {
    setArticlesForReview(props.articlesForReview);
  }, [props.articlesForReview]);
  
  useEffect(() => {
    setSelAuthorId(props.selAuthorId);
  }, [props.selAuthorId]);
  useEffect(() => {
    setSelPosttype(props.selPosttype);
  }, [props.selPosttype]);
  
  // useEffect(() => {
  //   setUserHasAlreadyCommented(props.userHasAlreadyCommented);
  // }, [props.userHasAlreadyCommented]);
  useEffect(() => {
    setUserHasAlreadyRated(props.userHasAlreadyRated);
  }, [props.userHasAlreadyRated]);
  
  
  useEffect(() => {
    setReviewerRequestsEmails(props.reviewerRequestsEmails);
  }, [props.reviewerRequestsEmails]);
  useEffect(() => {
    setReviewerRequestsDetails(props.reviewerRequestsDetails);
  }, [props.reviewerRequestsDetails]);

  useEffect(() => {
    attachmentFiles = props.attachmentFiles;
  }, [props.attachmentFiles]);
  useEffect(() => {
    setSelectedDraftPath(props.selectedDraftPath);
  }, [props.selectedDraftPath]);

  useEffect(() => {
    setArticleHtml(props.articleHtml);
  }, [props.articleHtml]);
  useEffect(() => {
    setArticleTitle(props.articleTitle);
  }, [props.articleTitle]);
  useEffect(() => {
    setArticleTags(props.articleTags);
  }, [props.articleTags]);

    var articleKey = "";
    // var post_type = 0; // article

    // Get a specific query parameter
    const articleId = new URLSearchParams(location.search).get('articleId');
    const questionId = new URLSearchParams(location.search).get('questionId');

    // load the article or question if we can find.
    // otherwise, we show a message that no such link was found on this server!

const getArticle = () => {

  if(ignoreReload) return;
  ignoreReload = true;
  console.log("-------------------------------Bhairav, calling getArticle");

      var formData = new FormData();
      if(articleId===null && questionId===null) return "";
      if(articleId==="" && questionId === "") return "";
      var aKey = "";
      var pt = 0;

      if(articleId === null || articleId === "") {
        aKey = questionId;
        pt = 1;
      } else {
        aKey = articleId;
      } 

      formData.append('articleKey', aKey);
      articleKey = aKey;
      // post_type = pt;
      var userDataJson = sessionStorage.getItem("userData");
      var userId = "0"; // not logged in
      var email = "";
      if(userDataJson !== null) {
        var userData = JSON.parse(userDataJson);
        if(userData !== undefined && userData !== null) {
          userId = userData.userId;
          email = userData.email;
        }
      }
      formData.append('userId', userId);
      formData.append('email', email);
      formData.append('articlesTableName', JsonData.ArticlesTableName);
      var sPost = "questions";
      if(pt === 0) {
        sPost = "articles";
      }
      
      articlePath = email + '/' + sPost + '/' + articleKey;

      fetch('/api/getArticle.php', {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
        body: formData,
      })
        // .then(response => response.text())
        .then(response => response.json())
        .then(data => {
            // window.alert(data);
          
          var art = data.data.article;
          
          var comments = data.data.comments;
          var commented = data.data.hasUserAlreadyCommented;
          var rated = data.data.hasUserAlreadyRated;
          attachmentFiles = data.data.files;
          var authorProfile = data.data.profile;
          articlePath = data.data.articlePath;
          var nVotes = data.data.nVotes;
          var netRating = data.data.netRating;
          setArticleNVotes(nVotes);
          setArticleRating(netRating);

          var author_email = data.data.author_email;          
          setAuthorEmail(author_email);

          setArticleAuthorProfile(authorProfile);
          setUserHasAlreadyCommented(commented);
          setUserHasAlreadyRated(rated);

          setPostArticle(art);
          setPostComments(comments);

          console.log(data.message);
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          alert.show(error, {type: 'error'});
        });
      }

  const handleDiscardButtonClick = () => {
    props.handleDiscardButtonClick();
  }

  const afterArticleSubmit = (post_type) => {
    // having submitted an article,
    // the user would want to:
    // 1. get to list of his own articles if he submitted an article
    //    so we show his own article list.
    // 2. view all articles? - perhaps no
    // 3. view all questions? - well, no
    // 4. view his questions? - if he submitted a questions
    //    so we show his own question list.
    if(post_type === 0) {
      // article.. show his list of articles...
      props.handleChangeViewItem("showMyArticlesList");
    } else {
      // question.. show his list of questions...
      props.handleChangeViewItem("showMyArticlesList");
    }
  }
 
  const getUserDetails = () => {
    var udJson = sessionStorage.getItem("userData");
    var ud = JSON.parse(udJson);
    var requesterEmail = ud.email;

    var formData = new FormData();
    formData.append('userEmail', userEmail);
    formData.append('requesterEmail', requesterEmail);

    fetch('/api/getUser.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData,
    })
      //.then(response => response.text())
      .then(response => response.json())
      .then(data => {
        //  alert(data);
        console.log(data.message);
        if(data.status === "logout") {
         // perform logout operation.
         alert.show(data.message, {type: 'info'});
         props.onLogout();
        } else if (data.status === "success") {
          var uDet = data.data;
          setUserDetails(uDet);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        alert.show(error, {type: 'error'});
      });
  }

  const onGetDetailsClicked = (event) => {
    event.preventDefault();
    getUserDetails();
  }

  const onUserEmailChanged = (event) => {
    event.preventDefault();
    setUserEmail(event.target.value);
  }

  const onTogglePost = (post, newValue) => {
    var userEmail = userDetails['Email'];

    var udJson = sessionStorage.getItem('userData');

    if(udJson === null) {
      alert.show("You must be logged in as administrator to perform this action.", {type: 'error'});
      return;
    }
    var ud = JSON.parse(udJson);
    var userId = "0"; // not logged in
    if(ud!== undefined || ud !== null) {
      userId = ud.userId;
    }
    if(userId === 0) {
      alert.show("You must be logged in as administrator to perform this action.", {type: 'error'});
      return;
    }

    var adminEmail = ud.email;
    var columnToChange = post;
 
    var formData = new FormData();

    formData.append('userEmail', userEmail);
    formData.append('adminEmail', adminEmail);
    formData.append('columnToChange', columnToChange);
    formData.append('newValue', newValue);

    fetch('/api/changeUserResponsibility.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData,
    })
      // .then(response => response.text())
       .then(response => response.json())
      .then(data => {
        // alert(data);
         if (data.status === "success") {
           var uDet = data.data;
           setUserDetails(uDet);
         }

        console.log(data.message);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        alert.show(error, {type: 'error'});
      });
  }

  const getPostInfo = (post) => {
    var fullPost = 'Is' + post;
    var p = userDetails[fullPost];
    if(p) {
      return <><p>Yes &nbsp; <button type="button" class="btn btn-link" onClick={(event)=>{event.preventDefault(); onTogglePost(fullPost, false)}}>Clear (unmark) as {post}</button></p></>
    } else {
      // return <><br></br><button class="btn btn-primary" onClick={onToggleReviewer}>Add as Reviewer</button></>
      return <><p>No &nbsp; <button type="button" class="btn btn-link" onClick={(event)=>{event.preventDefault(); onTogglePost(fullPost, true)}}>Mark as {post}</button></p></>
    }
  }

  const getUserDetailsHtml = () => {
    if(userDetails === null) return <></>
    if(userDetails.length === 0) return <></>
 
    return (
      <>
        <form>
            <div class="container mt-3">
              <table class="table table-striped">
                <tbody>
                  <tr><td>Email</td><td>{userDetails['Email']}</td></tr>
                  <tr><td>Full Name</td><td>{userDetails['FullName']}</td></tr>
                  <tr><td>Location</td><td>{userDetails['Location']}</td></tr>
                  <tr><td>Profile</td><td><QuillViewer Html={userDetails['Profile']} /> </td></tr>
                  <tr><td>Joining Date & Time</td><td>{userDetails['JoiningDateTime']}</td></tr>
                  <tr><td>Is Head</td><td>{getYesNo(userDetails['IsHead'])}</td></tr>
                  <tr><td>Is Admin</td><td>{getYesNo(userDetails['IsAdmin'])}</td></tr>
                  <tr><td>Is Reviewer</td><td>{getPostInfo('Reviewer')}</td></tr>
                  <tr><td>Is Moderator</td><td>{getPostInfo('Moderator')}</td></tr>
                  <tr><td>Is Blacklisted</td><td>{getPostInfo('Blacklisted')}</td></tr>
                  <tr><td>Is Verified</td><td>{getPostInfo('Verified')}</td></tr>
                  <tr><td>Level</td><td>{userDetails['Level']}</td></tr>
                  <tr><td>Badges</td><td>{userDetails['Badges']}</td></tr>
                  <tr><td>Accolades</td><td>{userDetails['Accolades']}</td></tr>
                  <tr><td>Number Of Articles</td><td>{userDetails['NumberOfArticles']}</td></tr>
                  <tr><td>Number Of Questions</td><td>{userDetails['NumberOfQuestions']}</td></tr>
                </tbody>
              </table>
            </div>
          </form> 
      </>
    )
  }

  const getYesNo = (boolVal) => {
    if(boolVal) return <>Yes</>
    return <>No</>
  }


  const approveReviewerRequest = (email) => {
    var udJson = sessionStorage.getItem('userData');
    if(udJson === null) {
      alert.show("You must be logged in as administrator to perform this action.", {type: 'error'});
      return;
    }
    var ud = JSON.parse(udJson);
    var userId = "0"; // not logged in
    if(ud!== undefined || ud !== null) {
      userId = ud.userId;
    }
    if(userId === 0) {
      alert.show("You must be logged in as administrator to perform this action.", {type: 'error'});
      return;
    }

    var adminEmail = ud.email;
    var columnToChange = 'IsAdmin';
    var newValue = true;

    var formData = new FormData();

    formData.append('userEmail', email);
    formData.append('adminEmail', adminEmail);
    formData.append('columnToChange', columnToChange);
    formData.append('newValue', newValue);

    fetch('/api/changeUserResponsibility.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData,
    })
      // .then(response => response.text())
       .then(response => response.json())
      .then(data => {
        // alert(data);
         if (data.status === "success") {
        
           setReviewerRequestsEmails(data.data.emails);
           setReviewerRequestsDetails(data.data.details);
          }

        console.log(data.message);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        alert.show(error, {type: 'error'});
      });
  }


  const getActiveReviewerRequestRow = (index) => {
    var e = reviewerRequestsEmails[index];
    return <><tr><td>{reviewerRequestsEmails[index]}</td><td>{<QuillViewer Html={reviewerRequestsDetails[index]}/> } </td><td><button type="button" class="btn btn-link" onClick={(event)=>{event.preventDefault(); approveReviewerRequest(e)}} >Approve</button></td> </tr></>
  }

  const getReviewerRequests = () => {
    if(reviewerRequestsEmails === undefined) {
      return <>No active reviewer requests at this time.</>
    }
    if(reviewerRequestsDetails === undefined) {
      return <>No active reviewer requests at this time.</>
    }
    var n = reviewerRequestsEmails.length;

    if(n === 0) return <>No active reviewer requests at this time.</>

    var indices = Array.from(reviewerRequestsEmails.keys());

    return <>
    <form>
      <div class="container mt-3">
        <table class="table table-striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Details</th>
            <th></th>
          </tr>
      </thead>
          <tbody>
            <tr><td>Email</td><td>{userDetails['Email']}</td></tr>
            {indices.map((i) => getActiveReviewerRequestRow(i))}
          </tbody>
        </table>
      </div>
    </form> 
  </>
  }
 
  const getFormattedDescription = () => {
    var htmlContent = JsonData.Description;

    htmlContent = "<h3>" + htmlContent + "</h3>";
    
    return ( 
        <div>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        </div>
      );
  }

  const getPostToShow = () => {
    switch(command) {
      case "showReviewerRequests":
        return <>{getReviewerRequests()}</>
      case "showUserManagement":
         return <>
          <p><b>User Management</b></p>
          <form>
            <div class="mb-3 mt-3">
              <label for="useremail" class="form-label">User Email:</label>
              <input onChange={onUserEmailChanged} type="email" class="form-control" id="useremail" placeholder="Enter user email" name="useremail" />
            </div>
            <button class="btn btn-primary" onClick={onGetDetailsClicked}>Get Details</button>
            <br></br>
            {getUserDetailsHtml()}
          </form> 
          </>

      case "showArticleEditor":
        
        return <ArticleEditor
                  articleHtml={articleHtml}
                  files={attachmentFiles}
                  draftPath={selectedDraftPath}
                  articleTitle={articleTitle}
                  articleTags={articleTags}
                  post_type={props.post_type}
                  handleSaveButtonClick={props.handleSaveArticleButtonClick}
                  setArticleTitle={props.setArticleTitle}
                  setArticleTags={props.setArticleTags}
                  setArticleHtml={props.setArticleHtml}
                  selectedArticleKey={props.selectedArticleKey}
                  handleDiscardButtonClick={handleDiscardButtonClick}
                  afterArticleSubmit={afterArticleSubmit}
               />
      case 'showArticleOrQuestion':

        getArticle();

        if(postArticle === null)
         {
          return <p>Article is null.</p>
         }
         
         if(postArticle === "")
         {
          return (<p>Article is empty.</p>)
         }

        var article = JSON.parse(postArticle);

        if(article === undefined || article === null) {
          return <p>Could not load/find article/question.</p>
        }
        
        var aKey = "";

        if(articleId === null || articleId === "") {
          aKey = questionId;
        } else {
          aKey = articleId;
        }

        return <>
                  <ArticleViewer
                    articleTitle = {article.Title}
                    articleTags = {article.Tags}
                    articleHtml = {article.Matter}
                    authorName = {article.Author}
                    files={attachmentFiles}
                    draftPath={articlePath}
                    userHasAlreadyCommented={userHasAlreadyCommented}
                    userHasAlreadyRated={userHasAlreadyRated}
                    articleKey={aKey}
                    post_type={props.post_type}
                    updateComments={updateComments}
                    authorProfile={articleAuthorProfile}
                    articlesForReview={articlesForReview}
                    authorEmail={authorEmail}
                    nVotes={articleNVotes}
                    rating={articleRating}
                    refreshReviewersList={props.refreshReviewersList}
                  />
                  <br/>
                  <CommentsViewer 
                  comments={postComments} 
                  articleKey={aKey} 
                  updateComments={updateComments} 
                  />
                  {/* <AdHostHorizontal /> */}
                </>
      case "refresh":
        return <p>Loading...</p>
      case "showUserProfile":
        var jud = sessionStorage.getItem('userData');
        var ud = JSON.parse(jud);
        return <ProfileEditor fullname={ud.userFullname} profileHtml={ud.profile} />
      case "showDashboard":
        return (
          <Dashboard 
            articles={props.articles}
            questions={props.questions}
            onHandleCreateNewArticleDraftClick={props.handleCreateNewArticleDraftRequest} 
            handleCreateDraftArticle={props.handleCreateDraftArticle}
            handleOnMyArticlesClick={props.handleOnMyArticlesClick}
            getDraftList={props.getDraftList}
            onDraftClicked={props.onDraftClicked}
            onDeleteDraftArticleClicked={props.onDeleteDraftArticleClicked}
            />
        )
      case "showMyArticlesList":
          return (
            <>
            <ArticleList 
            handleArticlePreviewClicked={props.handleArticlePreviewClicked} 
            reloadArticleList={reloadArticleList}
            wholeAreaClickable={props.wholeAreaClickable}
            onlyForEditing={onlyForEditing}
            onlyForReview={onlyForReview}
            searchString={props.searchString}
            selAuthorId={selAuthorId} 
            selPosttype={selPosttype} 
            articleList={articleList}
            allArticleList={allArticleList}
            />
            {/* <AdHostHorizontal /> */}
            </>
          )
      case "siteIntro":
      default:
        if (searchParams.has('userid')) {
          searchParams.delete('userid');
          setSearchParams(searchParams);
        }
        if (searchParams.has('token')) {
          searchParams.delete('token');
          setSearchParams(searchParams);
        }
    
        return  getFormattedDescription() 
    }
  }

  const updateComments = (newComments) => {
    setPostComments(newComments);
  }
  
  return <>
    {/* {<ListSelectors  selectMainAreaCommand={props.selectMainAreaCommand} setAuthorAndPosttypeForList={setAuthorAndPosttypeForList}/>} */}
    { getPostToShow() }
   </>

}

export default MainArea;
