import * as React from "react";
import { useState } from "react";
import DraftList from "./DraftList"
import { useAlert } from 'react-alert'

var selectedPostType = 0;

function Dashboard(props) {
  const [selectedDraftPath, setSelectedDraftPath] = useState("");
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const alert = useAlert();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const username = userData?.username;
  const email = userData?.email;

  const isLoggedIn = sessionStorage.getItem("loggedIn");
 
  // useEffect(() => {
  //   const loggedIn = sessionStorage.getItem("loggedIn");
  //   if (!loggedIn) {
  //     navigate("/");
  //   }
  // }, [navigate]);

  
  const handleCreateDraftArticle = (post_type) => {
    
    var formData = new FormData();
    formData.append('email', email);
    formData.append('post_type', post_type); // post_type = 0 => article, 1 for question
    formData.append('author', username); 
    
    selectedPostType = post_type;
    
    fetch('/api/createDraft.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData
    })
      // .then(response => response.text())
      .then(response => response.json())
      .then(data => {
        // window.alert(data);
        // Handle the data received from the PHP script
        console.log(data);
        if(data.status === "success") {
          setAttachmentFiles([]);
          sessionStorage.setItem("draftArticlePath", data.data.path);
          sessionStorage.setItem("attachmentFiles", attachmentFiles);
          sessionStorage.setItem("selectedDraftPath", selectedDraftPath);

          setSelectedDraftPath (data.data.path);
          // because we are creating a new draft, 
          // there would be no files in the drafts
          // folder. so the attachmentFiles will be just an empty array
          setAttachmentFiles([]);
          // window.location.href = "/dashboard"; 
          // pass the control back to App.js to enable showing the editor in the main area.

          props.onHandleCreateNewArticleDraftClick(email, data.data.path, [], selectedPostType);
        }
        else if(data.status === "error") {
          alert.show(data.message, {type: 'error'});
        }
        else if(data.status === "logout") {
          alert.show(data.message, {type: 'error'});
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error fetching data:', error);
        alert.show(error, {type: 'error'});
      });
  }
 
  


  const handleRefreshDraftList = (event) =>{
    getDraftList();
  }

    
  const getDraftList = () => {
     props.getDraftList();
  };

  const getDashboardItems = () => {
    // possibilities:
    // --> is logged in
    //    --> showDrafts 
    //    --> MyArticles
    //    --> AllArticles
    //    --> CreateNewArticle
    //    --> CreateNewQuestion
    // --> is logged out
    //    --> ViewAllArticles


    if(isLoggedIn) {
      return <>
        <div><br></br><h3>Add a new component or a code snippet</h3></div>
          { getDrafts() }<br/>
          { <span style={{cursor:"pointer"}} onClick={onMyArticlesClick}>My Components</span> }<br/>
          {/* { <span style={{cursor:"pointer"}}>My Questions</span> }<br/> */}
          { <span style={{cursor:"pointer"}}>All Components</span> }<br/>
          {/* { <span style={{cursor:"pointer"}}>All Questions</span> } */}
        </>
    } else {
      return <p>You must be logged in to add components.</p>
    }
  }
// ccffff
  const getDrafts = () => {
    return (
      <div class="blog dashboard h-100" style={{margin:"0px", padding:"10px"}} > 
        <span style={{cursor:"pointer"}} onClick={handleRefreshDraftList}>Refresh Drafts</span> 
        <h4>Draft Components & Code Snippets</h4>
        <button type="button" class="btn btn-link" onClick={(e) => {e.preventDefault(); handleCreateDraftArticle(0);}}>Create New Component</button>
        {/* &nbsp;&nbsp; */}
        {/* <button type="button" class="btn btn-link" onClick={(e) => {e.preventDefault(); handleCreateDraftArticle(1);}}>Create New Question</button> */}
        <br></br>
        <br></br>
        <DraftList articles={props.articles} questions={props.questions} onDraftClicked={props.onDraftClicked} onDeleteDraftArticleClicked={props.onDeleteDraftArticleClicked}/>
      </div>
      )
  }

  const onMyArticlesClick = () => {
    props.handleOnMyArticlesClick();
  }


  return <>{ getDashboardItems() }</>
}

export default Dashboard;
