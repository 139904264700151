import './App.css';
import {React, useState, useEffect} from "react";
// import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {HashRouter as Router, Route } from 'react-router-dom';

import Header from './components/Layout/Header/Header'
import LoginModal from './components/Modals/LoginModal'
import RegisterModal from './components/Modals/RegisterModal'
import Article from './components/Article'
import Navbar from './components/Layout/Navbar/Navbar'
import { useAlert } from 'react-alert'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function App() {
  const[email, setEmail] = useState("");
  const [isLoginModalVisible, setLoginModalState] = useState(false);
  const [isRegisterModalVisible, setRegisterModalState] = useState(false);
  const [username, setUsername] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [mainAreaCommand, setMainAreaCommand] = useState("");
  const [aModalIsShown, setAModalIsShown] = useState(false);
  const [searchString, setSearchString] = useState([]);
  const [reviewerRequestsEmails, setReviewerRequestsEmails] = useState([]);
  const [reviewerRequestsDetails, setReviewerRequestsDetails] = useState([]);
  const alert = useAlert();
  
  useEffect(() => {
    var ssLoggedIn = sessionStorage.getItem('loggedIn');
    console.log("reloading page");
    console.log("ssLoggedIn = " + ssLoggedIn);
    console.log("loggedIn = " + loggedIn);

    if(loggedIn !== ssLoggedIn) {
      setLoggedIn(ssLoggedIn);
      var udj = sessionStorage.getItem("userData");
      var ud = JSON.parse(udj);

      setUsername(ud?.username);
    }
    window.onbeforeunload = function() {
        return true;
    };
    return () => {
        window.onbeforeunload = null;
    };
}, [loggedIn]);

  useEffect(()=>{
    // we switch this off because we are not able to / do not want to store php session
    // on local browser as that would be a terrible security decision. We do not allow "remember me" feature
    // till we figure out the best approach to implement it.
    // checkLoginOnLocalStorage();
  }, []); // <-- empty dependency array. This forces run only once such as on a constructor. 
  // See: https://stackoverflow.com/questions/58101018/react-calling-a-method-on-load-only-once

  // // check if login credentials are available in the storage
  // const checkLoginOnLocalStorage = () => {
    
  //   let ls_userData = localStorage.getItem("userData");
  //   let ls_loggedIn = localStorage.getItem("loggedIn");

  //   if(ls_loggedIn !== undefined && ls_loggedIn === "true" && ls_userData !== undefined) {
  //     // we have a userData available in local storage
  //     sessionStorage.setItem("loggedIn", true);
  //     sessionStorage.setItem("userData", ls_userData);
  //     onLogin();
  //   } else {
  //     sessionStorage.setItem("loggedIn", false);
  //     var default_userData = 
  //     {
  //       username: "", 
  //       email: "",
  //       userId: 0,
  //       userFullname: "Guest",
  //       userLevel: "0",
  //       userBadges: "",
  //       userAccolades: "",
  //       profile: ""
  //     };
  //     sessionStorage.setItem("userData", JSON.stringify(default_userData));
  //   }
  // }


  const hideAllModals = () => {
    hideLoginModal();
    hideRegisterModal();
    setAModalIsShown(false);
  }

  const hideLoginModal = () => {
    setLoginModalState(false);
  };

  const showLoginModal = () => {
    hideAllModals();
    setAModalIsShown(true);
    setLoginModalState(true);
  };

  const hideRegisterModal = () => {
    setRegisterModalState(false);
  };


  const showRegisterModal = () => {
    hideAllModals();
    setAModalIsShown(true);
    setRegisterModalState(true);
  };
 
  const onRegister = () => { 
    window.alert("You have been successfully registered and verified. Please login to continue.");
  }

  const onLogin = () => {
    hideAllModals();
    var userData = JSON.parse(sessionStorage.getItem("userData"));
    setEmail(userData.email);
    setUsername(userData.userFullname);
    setLoggedIn(true);
    console.log("onLogin completed");
  }

  const onLogout = () => {
    sessionStorage.clear();
    localStorage.clear();

    sessionStorage.setItem("loggedIn", false);
    localStorage.setItem("loggedIn", false);

    var default_userData = 
      {
        username: "", 
        email: "",
        userId: 0,
        userFullname: "Guest",
        userLevel: "0",
        userBadges: "",
        userAccolades: "",
        profile: "",
        isAdmin: false,
        isReviewer: false,
        isModerator: false
      };
    sessionStorage.setItem("userData", JSON.stringify(default_userData));
    localStorage.setItem("userData", JSON.stringify(default_userData));

    setEmail("");
    setUsername("");
    setLoggedIn(false);

    setMainAreaCommand("showMyArticlesList");
    
  }

  const onProfileButtonClick = () => {
    setMainAreaCommand("showUserProfile");
  }

  const selectMainAreaCommand = (command) => {
    setMainAreaCommand(command);
  }

  const updateSearchString = (str) =>{
    setSearchString(str);
  }

  const onManageUsers = () => {
    setMainAreaCommand("showUserManagement");
  }

  const getActiveReviewerRequests = () => {
    var formData = new FormData();
    formData.append('email', email);

    fetch('/api/getActiveReviewerRequests.php', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: formData,
    })
      //  .then(response => response.text())
       .then(response => response.json())
      .then(data => {
        // alert(data);
        // alert("parsing");
        // var data = JSON.parse(data);
        // alert("parsed");
        // alert(data.message);
        console.log(data.message);
        if(data.status === "success") {
         setReviewerRequestsEmails(data.data.emails);
         setReviewerRequestsDetails(data.data.details);
        } else {
          alert.show(data.message, {type: 'error'});
          // alert(data.message);
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        // alert(error);
        alert.show(error, {type: 'error'});
      });
  }

  const onViewReviewerRequests = () => {
    getActiveReviewerRequests();
    setMainAreaCommand("showReviewerRequests");
  }

//  alert("loggedin = " + loggedIn);
// alert("sessionStorage.loggedin = " + sessionStorage.loggedIn);
// alert('username = ' + username);

  //  var ssLoggedIn = sessionStorage.getItem('loggedIn');
  //  alert("ssLogin = "    + ssLoggedIn);
  // if(loggedIn !== ssLoggedIn) {
  //   setLoggedIn(ssLoggedIn);
  // }

  // alert(sessionStorage.getItem('email'));

  const onBrandClick = () => {
    setMainAreaCommand("siteIntro");
  }

  return (
    <div>
      <Navbar 
        onHandleLoginClick={showLoginModal} 
        onHandleLogoutClick={onLogout} 
        onHandleRegisterClick={showRegisterModal}
        email={email} 
        loggedIn={loggedIn} 
        username={username} 
        onProfileButtonClick={onProfileButtonClick}
        updateSearchString={updateSearchString}
        onManageUsers={onManageUsers}
        onViewReviewerRequests={onViewReviewerRequests}
        onBrandClick={onBrandClick}
      />
      <Header />
      <Router>
        <Article 
          mainAreaCommand={mainAreaCommand} 
          selectMainAreaCommand={selectMainAreaCommand} 
          wholeAreaClickable={!aModalIsShown} 
          searchString={searchString} 
          onLogout={onLogout} 
          reviewerRequestsEmails={reviewerRequestsEmails} 
          reviewerRequestsDetails={reviewerRequestsDetails} 
        />
      </Router>          
      <Modal open={isLoginModalVisible} onClose={hideLoginModal} center>
        <LoginModal onClose={hideLoginModal} onRegister={showRegisterModal} onLogin={onLogin} />
      </Modal>
      
      <Modal open={isLoginModalVisible} onClose={hideLoginModal} center>
        <LoginModal isOpen={isLoginModalVisible} onClose={hideLoginModal} onRegister={showRegisterModal} onLogin={onLogin} />
      </Modal>

      <Modal open={isRegisterModalVisible} onClose={hideRegisterModal} center>
        <RegisterModal isOpen={isRegisterModalVisible} onClose={hideRegisterModal} onLogin={showLoginModal} onRegister={onRegister} />
      </Modal>
    </div>
  );
}

export default App;

 