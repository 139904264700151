import * as React from "react";
import { useState, useEffect } from "react";

function HeaderLinks(props) {
    const[linkTexts, setLinkTexts] = useState([]);
    const[linkActions, setLinkActions] = useState([]);
    const [linkType, setLinkType] = useState("button");
    const[searchString, setSearchString] = useState("");

    useEffect(() => {
        setLinkTexts(props.linkTexts);
      }, [props.linkTexts]);
    useEffect(() => {
        setLinkActions(props.linkActions);
      }, [props.linkActions]);
    useEffect(() => {
        setLinkType(props.linkType);
      }, [props.linkType]);
      
  const getLinkTextSpacer = (index) => {
    var n = linkTexts.length;
    if(index < n-1) {
        return <>&nbsp;&nbsp;</>
    } else {
        return <></>
    }
  }

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    setSearchString(event.target.value);
    props.setSearchString(event.target.value);
  };
  
  const getLinks = (i) => {
    var lt = "button";
    if(linkType !== undefined) {
        if(typeof(linkType) === "string") {
            if(linkType === "button") {
                lt = "button";
            } else if(linkType === "link") {
                lt = "link"; 
            }
        }
    }

    if(lt === "button") {
        return (<button type="button" class="btn btn-outline-primary" onClick={(e)=>{e.preventDefault(); linkActions[i]()}}>{linkTexts[i]}</button>)
    } else {
        return (<button type="button" class="btn btn-link" onClick={(e)=>{e.preventDefault(); linkActions[i]()}} >{linkTexts[i]}</button>)
    }
  }

  var indices = Array.from(linkTexts.keys());

  return (
    <div class="center">
    <div class="row">
        <div class="col-sm-3">
        </div>
        <div class="col-sm-9">
            <div class="input-group center">
                <form class="d-flex">
                <input class="form-control border-end-0 border rounded-pill" onChange={handleSearchStringChange} type="search" placeholder='Search' value={searchString} id="example-search-input"/>
                {/* <span class="input-group-append">
                    <button class="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5" type="button" onClick={handleSearchClick}>
                        <i class="fa fa-search"></i>
                    </button>
                </span> */}
                </form>
                &nbsp;&nbsp;
            {
                // indices.map((i) => getActiveReviewerRequestRow(i))
                indices.map((i) => <>{getLinks(i)}{getLinkTextSpacer(i)}</>)
            } 
</div>            </div>           
        {/* <div class="col-sm-3"></div> */}
    </div>
    </div>
  )
}

export default HeaderLinks;
